import {
  Button,
  Card,
  Classes,
  Dialog,
  H5,
  Icon,
  InputGroup,
  Intent,
  Label,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Spinner,
  SpinnerSize,
  Tooltip,
} from "@blueprintjs/core";
import {
  ItemListRendererProps,
  ItemPredicate,
  ItemRenderer,
  ItemRendererProps,
  Select,
} from "@blueprintjs/select";
import { DocumentReference } from "firebase/firestore";
import { debounce, startCase } from "lodash";
import { ChangeEvent, memo, ReactElement, useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/suppliers/actions";
import { Supplier } from "redux/suppliers/types";
import {
  addSupplier,
  isSupplierDeleted,
  updateSupplier,
} from "services/supplier";
import styles from "./index.module.scss";
import { COUNTRIES } from "./constants";
import { countBy } from "lodash";

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  searchedSupplier?: string;
  onFinish?: (createdSupplier?: Supplier) => void;
};

const INITIAL_STATE = {
  name: "",
  companyNumber: "",
  address: "",
  zip: "",
  city: "",
  country: "",
};

function AddSupplier({ isOpen, setOpen, searchedSupplier, onFinish }: Props) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.suppliers.loadingUpdate);
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loadingFindExistedSupplier, setLoadingFindExistedSupplier] =
    useState(false);
  const [revertSupplierLoading, setRevertSupplierLoading] = useState(false);
  const [newSupplier, setNewSupplier] = useState({
    ...INITIAL_STATE,
    ...(searchedSupplier && {
      name:
        searchedSupplier.charAt(0).toUpperCase() + searchedSupplier.slice(1),
    }),
  });
  const [isSupplierExist, setIsSupplierExist] = useState(false);
  const [revertDeletedSupplier, setRevertDeletedSupplier] =
    useState<DocumentReference | null>(null);
  const dataRedux = useAppSelector((state) => state.suppliers.data);

  const debouncedIsSupplierCheck = debounce(async (value: string) => {
    const isSupplierExist = dataRedux.find(
      (x) => x.name.toLowerCase() === value.toLowerCase() && !x.deleted
    );
    if (isSupplierExist) {
      setIsSupplierExist(true);
      return;
    } else {
      setIsSupplierExist(false);
    }
    setLoadingFindExistedSupplier(true);
    try {
      const isDeletedSupplier = await isSupplierDeleted(value);
      if (isDeletedSupplier.data) {
        setRevertDeletedSupplier(isDeletedSupplier.data);
      } else {
        setRevertDeletedSupplier(null);
      }
    } catch (error) {
      dispatch(
        alertActions.ERROR({
          message:
            typeof error === "string"
              ? error
              : error instanceof Error
                ? error.message
                : "An error occurred",
        })
      );
    } finally {
      setLoadingFindExistedSupplier(false);
    }
  }, 300);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setNewSupplier({ ...newSupplier, [e.target.name]: e.target.value });

    await debouncedIsSupplierCheck(e.target.value);
  };

  const handleUndeleteSupplier = async () => {
    if (!revertDeletedSupplier)
      dispatch(alertActions.ERROR("Supplier to revert not found"));
    try {
      setRevertSupplierLoading(true);
      if (revertDeletedSupplier) {
        await updateSupplier(revertDeletedSupplier.id, {
          deleted: false,
        });
        setRevertDeletedSupplier(null);
        setOpen(false);
        dispatch(alertActions.SUCCESS("Supplier restored successfully"));
      }
    } catch (error) {
      dispatch(
        alertActions.ERROR({
          message:
            typeof error === "string"
              ? error
              : error instanceof Error
                ? error.message
                : "An error occurred",
        })
      );
    } finally {
      setRevertSupplierLoading(false);
    }
  };

  const addNewSupplier = async () => {
    if (!newSupplier.name) {
      dispatch(alertActions.ERROR("Name is required"));
      return;
    }
    try {
      dispatch(actions.SET_STATE({ loadingUpdate: true }));
      const supplierToAdd = { ...newSupplier, deleted: false } as Supplier;
      const newCreatedSupplier = await addSupplier(supplierToAdd);
      if (onFinish && newCreatedSupplier.data) {
        onFinish({ ...supplierToAdd, id: newCreatedSupplier.data });
      }
      dispatch(alertActions.SUCCESS("Supplier added successfully"));
    } catch (error) {
      dispatch(
        alertActions.ERROR({
          message:
            typeof error === "string"
              ? error
              : error instanceof Error
                ? error.message
                : "An error occurred",
        })
      );
    } finally {
      dispatch(actions.SET_STATE({ loadingUpdate: false }));
      setNewSupplier(INITIAL_STATE);
    }
  };

  const filterCountries: ItemPredicate<string> = (query, item) => {
    return `${item.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
  };

  const renderCountries: ItemRenderer<string> = (
    item,
    { modifiers, handleClick }: ItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) return null;
    return (
      <MenuItem
        key={item}
        active={modifiers.active}
        multiline
        onClick={handleClick}
        roleStructure="listoption"
        text={item}
      />
    );
  };

  const handleSelectCountry = (country: string) => {
    setNewSupplier({ ...newSupplier, country });
  };

  interface ItemGroupProps {
    name: string;
    items: string[];
    renderItem: (item: string, index: number) => ReactElement | null;
  }

  const ItemGroup = memo(({ name, items, renderItem }: ItemGroupProps) => (
    <>
      <MenuDivider key={name} title={name} />
      {items.map((item, i) => renderItem(item, i))}
    </>
  ));

  const itemListRenderer = ({
    menuProps,
    renderItem,
  }: ItemListRendererProps<string>) => {
    const countedCountries = countBy(suppliers.map((x) => x.country));
    const top5Countries = Object.entries(countedCountries)
      .sort(([, a], [, b]) => b - a)
      .map(([country]) => country)
      .slice(0, 5);
    const groupedItems = [
      {
        group: "Suggestions - Top 5 most used countries",
        items: top5Countries,
      },
      {
        group: "Countries",
        items: COUNTRIES,
      },
    ];
    return (
      <Menu {...menuProps}>
        {groupedItems.map((group) => (
          <ItemGroup
            key={group.group}
            name={group.group}
            items={group.items}
            renderItem={renderItem}
          />
        ))}
      </Menu>
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setOpen(!isOpen)}
      autoFocus
      enforceFocus
      usePortal
      title="Add Supplier"
    >
      <Card>
        {["name", "companyNumber", "address", "zip", "city"].map((field) => (
          <Label key={field}>
            {startCase(
              field === "companyNumber" ? "Organization Number" : field
            )}
            <InputGroup
              name={field}
              value={newSupplier[field as keyof typeof newSupplier]}
              onChange={onChange}
              intent={
                field === "name" && isSupplierExist
                  ? Intent.DANGER
                  : Intent.NONE
              }
              rightElement={
                field === "name" &&
                !isSupplierExist &&
                newSupplier[field] !== "" ? (
                  loadingFindExistedSupplier ? (
                    <Spinner size={SpinnerSize.SMALL} />
                  ) : revertDeletedSupplier ? (
                    <Popover
                      enforceFocus={true}
                      isOpen={revertDeletedSupplier !== null}
                      position="right"
                      fill
                      content={
                        <div>
                          <H5>{`Restore Supplier`}</H5>
                          <p>{`The supplier already exists but has been deleted. Do you want to restore this supplier?`}</p>
                          <div className="button-display">
                            <Button
                              text="Cancel"
                              onClick={() => setRevertDeletedSupplier(null)}
                              style={{ marginRight: 10 }}
                            />
                            <Button
                              id="confirm-button"
                              intent={Intent.DANGER}
                              className={Classes.POPOVER_DISMISS}
                              onClick={handleUndeleteSupplier}
                              onKeyDown={handleUndeleteSupplier}
                              text={"Ok"}
                              loading={revertSupplierLoading}
                            />
                          </div>
                        </div>
                      }
                      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    >
                      <Icon icon={"cross"} size={20} style={{ marginTop: 2 }} />
                    </Popover>
                  ) : (
                    <Tooltip content={"New supplier name"} position="right">
                      <Icon icon={"tick"} size={20} style={{ marginTop: 2 }} />
                    </Tooltip>
                  )
                ) : undefined
              }
            />
            {isSupplierExist && field === "name" && (
              <Label className="mt-2">Supplier already exists</Label>
            )}
          </Label>
        ))}
        <Label>
          Country
          <Select
            filterable={true}
            items={COUNTRIES}
            itemPredicate={filterCountries}
            query={searchQuery}
            onQueryChange={setSearchQuery}
            itemRenderer={renderCountries}
            onItemSelect={handleSelectCountry}
            noResults={<MenuItem text="No results." disabled={true} />}
            resetOnSelect={true}
            popoverProps={{
              matchTargetWidth: true,
              minimal: true,
              placement: "bottom",
              positioningStrategy: "fixed",
              rootBoundary: "viewport",
            }}
            inputProps={{
              placeholder: "Country search...",
            }}
            itemListRenderer={itemListRenderer}
          >
            <div className={styles.select_country_btn}>
              {newSupplier.country || "Select country"}
              <Icon icon="double-caret-vertical" />
            </div>
          </Select>
        </Label>
        <Button
          fill
          className="mt-4"
          loading={loading}
          onClick={async () => await addNewSupplier()}
          disabled={
            newSupplier.name === "" ||
            revertDeletedSupplier !== null ||
            loadingFindExistedSupplier ||
            isSupplierExist
          }
        >
          Add Supplier
        </Button>
      </Card>
    </Dialog>
  );
}

export default AddSupplier;
