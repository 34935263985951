import {
  DocumentData,
  DocumentReference,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";

import { EventChannel, eventChannel } from "redux-saga";
import {
  CalendarData,
  ImMatricData,
  ScrapersLog,
} from "redux/integration-monitor/types";
import { db } from "services/firebase";

export function setScrapersLogListener(
  userId: string,
  vendorName: string,
  lastDoc?: QueryDocumentSnapshot<DocumentData>,
  firstDoc?: QueryDocumentSnapshot<DocumentData>,
  direction?: "next" | "previous"
): EventChannel<{
  data: { id: string; ref: DocumentReference }[];
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  firstDoc?: QueryDocumentSnapshot<DocumentData>;
}> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const LIMIT = 100;

  let constraints: QueryConstraint[] = [limit(LIMIT)];
  if (direction) {
    constraints =
      direction === "next"
        ? [startAfter(lastDoc), limit(LIMIT)]
        : [endBefore(firstDoc), limitToLast(LIMIT)];
  }

  const scrapersLogsQuery = query(
    collection(db, "scrapersLogs"),
    where("userId", "==", userId),
    where("name", "==", vendorName),
    orderBy("createdAt", "desc"),
    ...constraints
  );

  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      scrapersLogsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map(
            (doc) => new ScrapersLog(doc.id, doc.ref, doc.data())
          );
          const returnedLastDoc =
            snapshot.docs.length !== LIMIT
              ? undefined
              : snapshot.docs[snapshot.docs.length - 1];
          const returnedFirstDoc = !direction ? undefined : snapshot.docs[0];

          emitter({
            data,
            lastDoc: returnedLastDoc,
            firstDoc: returnedFirstDoc,
          });
        }
      }
    );
    return () => unsub();
  });
}

export function setScrapersLogListenerByUserId(userId: string): EventChannel<{
  scraperLogIds?: {
    id: string;
    createdAt: Date;
    lastStep: {
      createdAt: Date;
      name: string;
    };
    vendor: string;
  }[];
}> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const logRef = query(
    collection(db, "scrapersLogs"),
    where("userId", "==", userId),
    where("name", "!=", "Daily_Sales"),
    where("success", "==", true),
    orderBy("createdAt", "desc")
  );

  return eventChannel((emitter) => {
    const unsub = onSnapshot(
      logRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs
            .filter((x) => x.data().steps.length > 1)
            .map((doc) => {
              const lastStep = doc.data().steps[doc.data().steps.length - 2];
              return {
                id: doc.id,
                createdAt: doc.data().createdAt?.toDate(),
                lastStep: {
                  name: lastStep.name,
                  createdAt: lastStep.createdAt.toDate(),
                },
                vendor: doc.data().name,
              };
            });

          emitter({ scraperLogIds: data });
        }
      }
    );
    return () => unsub();
  });
}

export function setImMetricsListener(
  dateFrom: Date,
  dateTo: Date
): EventChannel<ImMatricData[]> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const metricsRef = query(
    collection(db, "metricsIntegrationMonitor"),
    where("createdAt", ">=", moment(dateFrom).startOf("day").toDate()),
    where("createdAt", "<=", moment(dateTo).endOf("day").toDate())
  );

  return eventChannel((emitter) => {
    const unsub = onSnapshot(
      metricsRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              }) as ImMatricData
          );

          emitter(data);
        }
      }
    );
    return () => unsub();
  });
}

export function setCalendarDataListener(
  userId: string,
  vendorName: string,
  selectedDate?: Date
): EventChannel<CalendarData[]> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const constraints = [
    where("userId", "==", userId),
    where("vendor", "==", vendorName),
    where(
      "date",
      ">=",
      moment(selectedDate ?? new Date())
        .startOf("month")
        .toDate()
    ),
    where(
      "date",
      "<=",
      moment(selectedDate ?? new Date())
        .endOf("month")
        .toDate()
    ),
  ];

  const calendarDataRef = query(
    collection(db, "imCalendarData"),
    ...constraints
  );

  return eventChannel((emitter) => {
    const unsub = onSnapshot(
      calendarDataRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                date: doc.data().date.toDate(),
                id: doc.id,
              }) as CalendarData
          );

          emitter(data);
        }
      }
    );
    return () => unsub();
  });
}
