import { Button, InputGroup, NumericInput, Switch } from "@blueprintjs/core";

import { Text } from "@blueprintjs/core";
import AddDefaultFlag from "components/users/add-default-user-profile";
import CustomTypes from "components/users/edit-user-btn/custom-types";
import { httpsCallable } from "firebase/functions";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import alertActions from "redux/alert/actions";
import { useAppSelector } from "redux/hooks";
import settingsActions from "redux/settings/actions";
import { User } from "redux/users/types";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import style from "./index.module.scss";

type Props = {
  newDefaultUserProfile: Partial<User>;
  oldData: Partial<User>;
  setNewDefaultUserProfile: Dispatch<SetStateAction<Partial<User>>>;
};

type InputProps = {
  newDefaultUserProfile: Partial<User>;
  oldData: Partial<User>;
  setNewDefaultUserProfile: Dispatch<SetStateAction<Partial<User>>>;
  fieldKey: string;
  fieldType: string;
};

const DefaultProfileUserInput = ({
  fieldKey,
  fieldType,
  oldData,
  newDefaultUserProfile,
  setNewDefaultUserProfile,
}: InputProps) => {
  const inputFieldRenderer = () => {
    if (fieldType === "number") {
      return (
        <NumericInput
          fill
          value={newDefaultUserProfile[fieldKey as keyof User] as number}
          onValueChange={(valueAsNumber: number) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: valueAsNumber,
            })
          }
          id={`${fieldKey}-input`}
        />
      );
    } else if (fieldType === "string") {
      return (
        <InputGroup
          value={newDefaultUserProfile[fieldKey as keyof User] as string}
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: e.target.value,
            })
          }
          id={`${fieldKey}-input`}
        />
      );
    } else if (fieldType === "boolean") {
      return (
        <Switch
          checked={newDefaultUserProfile[fieldKey as keyof User] as boolean}
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: e.target.checked,
            })
          }
        />
      );
    } else if (fieldKey === "strictSearchSetting") {
      return (
        <Switch
          checked={
            newDefaultUserProfile.strictSearchSetting
              ?.isStrictSearchSettingEnabled as boolean
          }
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey as keyof string]: {
                ...newDefaultUserProfile[fieldKey],
                isStrictSearchSettingEnabled: e.target.checked,
              },
            })
          }
        />
      );
    } else if (fieldKey === "customTypes") {
      return (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <CustomTypes
            oldData={oldData}
            newData={newDefaultUserProfile}
            setNewData={setNewDefaultUserProfile}
            formGroupProps={{}}
            CustomLabel={(key: string) => (
              <div style={{ width: "180px" }}>
                <Text>{key}</Text>
              </div>
            )}
          />
        </div>
      );
    } else <></>;
  };

  return (
    <div className={fieldKey !== "customTypes" ? style.form__group : ""}>
      {fieldKey !== "customTypes" && <div>{fieldKey}:</div>}
      {inputFieldRenderer()}
    </div>
  );
};

function DefaultProfileUser({
  newDefaultUserProfile,
  oldData,
  setNewDefaultUserProfile,
}: Props) {
  const dispatch = useDispatch();
  const users = useAppSelector((state) => state.users.users);
  const [refreshDefaultUserFlagsLoading, setRefreshDefaultUserFlagsLoading] =
    useState(false);

  const attrArr = useMemo(() => {
    const result = [];
    for (const key in newDefaultUserProfile) {
      if (newDefaultUserProfile.hasOwnProperty(key)) {
        result.push({ key, type: typeof newDefaultUserProfile[key] });
      }
    }
    result.sort((a, b) => {
      if (a.key === "customTypes" || a.key === "customReportAreas") return 1;
      if (b.key === "customTypes" || b.key === "customReportAreas") return -1;
      return a.key.localeCompare(b.key);
    });
    return result;
  }, [newDefaultUserProfile]);

  const attrMap = useMemo(() => {
    const allAttributes = new Map();
    for (const user of users) {
      const userKeys = Object.keys(user);
      for (const property of userKeys) {
        const propValue = user[property];
        if (
          propValue !== null &&
          propValue !== undefined &&
          propValue !== "" &&
          (typeof propValue === "string" ||
            typeof propValue === "boolean" ||
            (typeof propValue === "number" && !isNaN(propValue)))
        ) {
          if (!allAttributes.has(property)) {
            allAttributes.set(property, typeof propValue);
          }
        }
      }
    }
    if (!allAttributes.has("hiddenUserIds")) {
      allAttributes.set("hiddenUserIds", typeof []);
    }
    if (!allAttributes.has("openPeriods")) {
      allAttributes.set("openPeriods", typeof []);
    }
    return allAttributes;
  }, [users]);

  const refreshDefaultUserFlags = async () => {
    if (!functions) return;
    setRefreshDefaultUserFlagsLoading(true);
    try {
      const refreshDefaultUserFlagsFn = httpsCallable<null, string>(
        functions,
        CALLABLE_FUNCTIONS.refreshDefaultUserFlags
      );
      const response = await refreshDefaultUserFlagsFn();
      dispatch(settingsActions.GET_DEFAULT_USER_PROFILE());
      dispatch(alertActions.SUCCESS(response.data));
    } catch (e) {
      console.error(e);
      dispatch(alertActions.ERROR("Failed to refresh default user flags"));
    } finally {
      setRefreshDefaultUserFlagsLoading(false);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
        <AddDefaultFlag fields={attrMap} />
        <Button
          loading={refreshDefaultUserFlagsLoading}
          text="Refresh default flags"
          icon="refresh"
          onClick={() => refreshDefaultUserFlags()}
        />
      </div>
      <div className="content">
        {attrArr.map((field) => {
          return (
            <DefaultProfileUserInput
              oldData={oldData}
              fieldKey={field.key}
              fieldType={field.type}
              newDefaultUserProfile={newDefaultUserProfile}
              setNewDefaultUserProfile={setNewDefaultUserProfile}
              key={`${field.key}-input`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default DefaultProfileUser;
