export const CALLABLE_FUNCTIONS = {
  addTripletexKey: "scripts-addTripletexKey",
  addPowerOfficeKey: "scripts-addPowerOfficeKey",
  archiveUnusedItems: "items-archiveUnusedItems",
  archiveUnusedPosItems: "items-archiveUnusedPosItems",
  archiveConnectedPosItemTasks: "scripts-archiveConnectedPosItemTasks",
  archiveAllPosItemTasks: "scripts-archiveAllPosItemTasks",
  batchUpdatePermissions: "permissions-batchUpdatePermissions",
  batchUpdateAdvancedFilteredUsers: "users-batchUpdateAdvancedFilteredUsers",
  createIntegration: "users-createIntegration",
  createPermission: "permissions-createPermission",
  createUserCopy: "users-createUserCopy",
  createInvoiceUser: "users-createInvoiceUser",
  createInvoice: "cloud-createInvoice",
  createOrganization: "users-createOrganization",
  createUser: "users-createUser",
  convertPdfToExcel: "scripts-convertPdfToExcel",
  deleteBundleName: "items-deleteBundleName",
  deleteSales: "scripts-deleteSales",
  disableLogin: "users-disableLogin",
  downloadEnheter: "scripts-downloadEnheter",
  downloadTemplate: "scripts-downloadTemplate",
  enableLogin: "users-enableLogin",
  fetchInvoiceSupplier: "invoices-fetchInvoiceSupplier",
  fetchUserPosItems: "users-fetchUserPosItemsCallable",
  fixCountItems: "scripts-fixCountItems",
  generateOcrData: "ocr-generateOcrData",
  getDetailedInvoiceOverview: "scripts-getDetailedInvoiceOverview",
  getCostVarianceGraph: "scripts-getCostVarianceGraph",
  getProfitData: "scripts-getProfitData",
  getProfitReport: "scripts-getProfitReport",
  shareCostVarianceReport: "scripts-shareCostVarianceReport",
  getSharedCostVarianceReport: "scripts-getSharedCostVarianceReport",
  getGulesiderData: "scripts-getGulesiderData",
  getGulesiderCount: "scripts-getGulesiderCount",
  getStockCountData: "scripts-getStockCountData",
  getAliases: "scripts-getAliases",
  getSuppliers: "scripts-getSuppliers",
  getInvoiceOverview: "scripts-getInvoiceOverview",
  getCountChecklist: "scripts-getCountChecklist",
  getCountedItems: "scripts-getCountedItems",
  getCumulativeCostReport: "scripts-getCumulativeCostReport",
  getDailySalesReport: "scripts-getDailySalesReport",
  getRobnoMaterijalnoReport: "scripts-getRobnoMaterijalnoReport",
  getCountedNotPurchasedSoldCountedItems:
    "items-getCountedNotPurchasedSoldCountedItems",
  getStockCountReport: "scripts-getStockCountReport",
  getCountedPurchasedSoldCounted: "items-getCountedPurchasedSoldCounted",
  getEmailsFromCloseAPI: "scripts-getEmailsFromCloseAPI",
  getUserItems: "scripts-getUserItems",
  getPriceChangesReport: "scripts-getPriceChangesReport",
  getPriceChangesReportV2: "scripts-getPriceChangesReportV2",
  getCustomClaims: "users-getCustomClaims",
  getCostVarianceReport: "scripts-getCostVarianceReport",
  getInvoicesBasedOnItem: "invoices-getInvoicesBasedOnItem",
  getDuplicateInvoices: "invoices-getDuplicateInvoices",
  importSales: "scripts-importSales",
  importGeminiList: "scripts-importGeminiList",
  importUserItems: "users-importUserItems",
  importItemCosts: "scripts-importItemCosts",
  importItemBundleNames: "scripts-importItemBundleNames",
  mergeCounts: "scripts-mergeCounts",
  refreshDefaultUserFlags: "users-refreshDefaultUserFlags",
  resetPassword: "users-resetPassword",
  replaceItem: "items-replaceItem",
  resizeItem: "items-resizeItem",
  saveInvoice: "invoices-saveInvoice",
  saveItemsData: "items-saveItemsData",
  sendUserNotification: "notifications-sendUserNotification",
  translateText: "cloud-translateText",
  typesenseSearch: "typesense-typesenseSearch",
  updateIngredientSizes: "scripts-updateIngredientSizes",
  updateIntegration: "integrations-updateIntegration",
  updateReportTemplate: "scripts-updateReportTemplate",
  updateVarianceReport: "updateVarianceReport",
  validateTripletexKey: "scripts-validateTripletexKey",
  validatePowerOfficeKey: "scripts-validatePowerOfficeKey",
  writeInvoiceCountCallable: "users-writeInvoiceCountCallable",
  writeIntegrationsData: "users-writeIntegrationsData",
  tankBeerSplit: "itemTransfers-tankBeerSplit",
  divideFoodIntoVarieties: "items-divideFoodIntoVarieties",
  sendGeminiInvoice: "invoices-sendGeminiInvoice",
  getActiveItems: "scripts-getActiveItems",

  // SCRAPERS
  APScrapers24Seven: "scrapers-24seven-create24SevenTask",
  APScrapersAzets: "scrapers-azets-createAzetsTask",
  APScrapersBazaro: "scrapers-bazaro-createBazaroTask",
  APScrapersCorpay: "scrapers-corpay-createCorpayTask",
  POSScrapersDuell: "scrapers-duell-createDuellTask",
  POSScrapersFavrit: "scrapers-favrit-createFavritTask",
  POSScrapersFavritWastage: "scrapers-favrit-createFavritWastageTask",
  POSScrapersFoodora: "scrapers-foodora-createFoodoraTask",
  APScrapersGoogleDrive: "scrapers-google-createGoogleDriveTask",
  POSScrapersGoogleMail: "scrapers-google-createGoogleMailPOSTask",
  APScrapersGoogleMail: "scrapers-google-createGoogleMailAPTask",
  POSScrapersLightspeed: "scrapers-lightspeed-createLightspeedTask",
  POSScrapersLightspeedWastage:
    "scrapers-lightspeed-createLightspeedWastageTask",
  POSScrapersLoomispay: "scrapers-loomispay-createLoomispayTask",
  APScrapersMillum: "scrapers-millum-createMillumTask",
  POSScrapersMunu: "scrapers-munu-createMunuTask",
  POSScrapersMunuWastage: "scrapers-munu-createMunuWastageTask",
  POSScrapersMunuCloud: "scrapers-munucloud-createMunuCloudTask",
  POSScrapersMunuCloudWastage: "scrapers-munucloud-createMunuCloudWastageTask",
  POSScrapersOrderX: "scrapers-orderx-createOrderXTask",
  POSScrapersOrderXWastage: "scrapers-orderx-createOrderXWastageTask",
  POSScrapersQuickorder: "scrapers-quickorder-createQuickorderTask",
  POSScrapersRemaris: "scrapers-remaris-createRemarisTask",
  POSScrapersRemarisHotel: "scrapers-remaris-createRemarisHotelTask",
  POSScrapersRemarisReceiptsNote:
    "scrapers-remaris-createRemarisReceiptsNoteTask",
  POSScrapersRestolution: "scrapers-restolution-createRestolutionTask",
  POSScrapersSuperb: "scrapers-superb-createSuperbTask",
  APScrapersUni24: "scrapers-uni24-createUni24Task",
  APScrapersUnimicro: "scrapers-unimicro-createUnimicroTask",
  APScrapersVisma: "scrapers-visma-createVismaTask",
  POSScrapersWolt: "scrapers-wolt-createWoltTask",
  POSScrapersSimphony: "scrapers-simphony-createSimphonyTask",
  SupplierScrapersAsko: "scrapers-asko-createAskoTask",
};
