import { createActions } from "redux/create-actions";
import {
  ADD_DOWNLOAD_URL_Payload,
  BATCH_CHECK_INVOICES_payload,
  CHECK_INVOICE_payload,
  CREATE_ITEM_Payload,
  DELETE_IMAGE_Payload,
  DELETE_VOTES_Payload,
  FILE_UPLOAD_Payload,
  GET_OCR_DATA_Payload,
  SUBSCRIBE_TO_CHECKED_INVOICES_Payload,
  SUBSCRIBE_TO_FEES_payload,
  SUBSCRIBE_TO_INVOICE_VOTES_payload,
  SUBSCRIBE_TO_TABLE_VIEW_INVOICES_Payload,
  SUBSCRIBE_TO_UNCHECKED_INVOICES_Payload,
  SUBSCRIBE_TO_USER_INVOICE_Payload,
  SUBSCRIBE_TO_USER_INVOICE_TABLE_Payload,
  SUBSCRIBE_TO_USER_ITEMS_Payload,
  SUBSCRIBE_TO_USER_VOTES_RESULTS_Payload,
  UPDATE_DOCUMENT_AI_DATA_Payload,
  UPDATE_INVOICE_Payload,
  UPDATE_ITEM_Payload,
  UPDATE_VOTE_Payload,
  UPLOAD_AND_CREATE_INVOICE_Payload,
  VOTE_INVOICE_Payload,
} from "./sagas";
import {
  AITable,
  Fee,
  Invoice,
  InvoiceState,
  Items,
  UserStatusInvoices,
} from "./types";

const actions = createActions("invoice", {
  SET_STATE: {} as Partial<InvoiceState>,
  UPDATE_USER_INVOICE_TABLE: {} as {
    userId: string;
    invoice: Invoice | null;
    loading: boolean;
  },
  UPDATE_USER_ITEMS_TABLE: {} as {
    userId: string;
    userItems: Items[];
    loading: boolean;
  },
  UPDATE_USER_FEES_TABLE: {} as {
    userId: string;
    userFees: Fee[];
    loading: boolean;
  },
  UPDATE_INVOICE: {} as UPDATE_INVOICE_Payload,
  UPDATE_VOTE: {} as UPDATE_VOTE_Payload,
  DELETE_VOTES: {} as DELETE_VOTES_Payload,
  CHECK_INVOICE: {} as CHECK_INVOICE_payload,
  BATCH_CHECK_INVOICES: {} as BATCH_CHECK_INVOICES_payload,
  VOTE_INVOICE: {} as VOTE_INVOICE_Payload,
  UPDATE_INVOICE_STATE: {} as Partial<Invoice>,
  CREATE_ITEM: {} as CREATE_ITEM_Payload,
  UPDATE_ITEM: {} as UPDATE_ITEM_Payload,
  SUBSCRIBE_TO_USER_ITEMS: {} as SUBSCRIBE_TO_USER_ITEMS_Payload,
  SUBSCRIBE_TO_USER_INVOICE: {} as SUBSCRIBE_TO_USER_INVOICE_Payload,
  SUBSCRIBE_TO_USER_INVOICE_VOTES: {} as SUBSCRIBE_TO_USER_INVOICE_Payload,
  SUBSCRIBE_TO_USER_VOTES_RESULTS:
    {} as SUBSCRIBE_TO_USER_VOTES_RESULTS_Payload,
  SUBSCRIBE_TO_UNCHECKED_INVOICES:
    {} as SUBSCRIBE_TO_UNCHECKED_INVOICES_Payload,
  SUBSCRIBE_TO_CHECKED_INVOICES: {} as SUBSCRIBE_TO_CHECKED_INVOICES_Payload,
  SUBSCRIBE_TO_TABLE_VIEW_INVOICES:
    {} as SUBSCRIBE_TO_TABLE_VIEW_INVOICES_Payload,
  SUBSCRIBE_TO_USER_INVOICE_TABLE:
    {} as SUBSCRIBE_TO_USER_INVOICE_TABLE_Payload,
  SUBSCRIBE_TO_USER_ITEMS_TABLE: {} as SUBSCRIBE_TO_USER_ITEMS_Payload,
  SUBSCRIBE_TO_USER_FEES_TABLE: {} as SUBSCRIBE_TO_USER_INVOICE_Payload,
  UNSUBSCRIBE_FROM_USER_FEES_TABLE: undefined,
  UNSUBSCRIBE_FROM_USER_VOTES_RESULTS: undefined,
  UNSUBSCRIBE_FROM_TABLE_VIEW_INVOICES: undefined,
  UNSUBSCRIBE_FROM_USER_ITEMS: undefined,
  UNSUBSCRIBE_FROM_USER_ITEMS_TABLE: undefined,
  UNSUBSCRIBE_FROM_USER_INVOICE_TABLE: undefined,
  UNSUBSCRIBE_FROM_USER_INVOICE: undefined,
  UNSUBSCRIBE_FROM_USER_INVOICE_VOTES: undefined,
  UNSUBSCRIBE_FROM_UNCHECKED_INVOICES: undefined,
  UNSUBSCRIBE_FROM_CHECKED_INVOICES: undefined,
  FILE_UPLOAD: {} as FILE_UPLOAD_Payload,
  ADD_DOWNLOAD_URL: {} as ADD_DOWNLOAD_URL_Payload,
  DELETE_IMAGE: {} as DELETE_IMAGE_Payload,
  GET_OCR_DATA: {} as GET_OCR_DATA_Payload,
  GET_DOCUMENT_AI_DATA: {} as GET_OCR_DATA_Payload,
  UPDATE_DOCUMENT_AI_TABLE_DATA: {} as { fileName: string; tables: AITable[] },
  UPDATE_DOCUMENT_AI_DATA: {} as UPDATE_DOCUMENT_AI_DATA_Payload,
  SUBSCRIBE_TO_FEES: {} as SUBSCRIBE_TO_FEES_payload,
  UNSUBSCRIBE_FROM_FEES: undefined,
  SUBSCRIBE_TO_INVOICE_VOTES: {} as SUBSCRIBE_TO_INVOICE_VOTES_payload,
  UNSUBSCRIBE_FROM_INVOICE_VOTES: undefined,
  SET_USER_ITEM_ALIASES: {} as { itemId: string; aliases: string[] },
  SET_USER_FEES_ALIASES: {} as { itemId: string; aliases: string[] },
  SET_USER_STATUS_INVOICES: {} as Partial<UserStatusInvoices>,
  INITIALIZE_USER_ITEM_ALIASES: {} as {
    newAliasesToInitialize: {
      [key: string]: string;
    }[];
  },
  UPLOAD_AND_CREATE_INVOICE: {} as UPLOAD_AND_CREATE_INVOICE_Payload,
});

export default actions;
