import { createActions } from "redux/create-actions";
import {
  ADD_ACTIVITY_NOTE_Payload,
  BATCH_UPDATE_USER_Payload,
  DELETE_ACTIVITY_NOTE_Payload,
  DISMISS_ALL_USER_COUNT_Payload,
  DISMISS_USER_COUNT_Payload,
  EDIT_NOTE_SUGGESTIONS_Payload,
  GET_USERS_Payload,
  GET_USER_TABLE_COLUMNS_Payload,
  GET_USER_TRANSACTION_DOWNLOAD_URL_Payload,
  SUBSCRIBE_TO_ACTIVITIES_payload,
  SUBSCRIBE_TO_SELECTED_USERID_payload,
  SUBSCRIBE_TO_USERS_payload,
  SUBSCRIBE_USER_TRANSACTIONS_Payload,
  UPDATE_ACTIVITY_NOTE_Payload,
  UPDATE_COUNT_CONFIRMED_STATUS_Payload,
  UPDATE_NOTE_SUGGESTIONS_Payload,
  UPDATE_USERS_TRANSACTIONS_RESOLVED_Payload,
  UPDATE_USER_CONFIRMED_STATUS_Payload,
  UPDATE_USER_Payload,
  UPDATE_USER_TABLE_COLUMNS_Payload,
} from "./sagas";
import { UsersState } from "./types";

const actions = createActions("users", {
  SET_STATE: {} as Partial<UsersState>,
  GET_USERS: {} as GET_USERS_Payload,
  UPDATE_USER: {} as UPDATE_USER_Payload,
  BATCH_UPDATE_USER: {} as BATCH_UPDATE_USER_Payload,
  SUBSCRIBE_TO_USERS: {} as SUBSCRIBE_TO_USERS_payload,
  UNSUBSCRIBE_FROM_USERS: undefined,
  DISMISS_USER_COUNT: {} as DISMISS_USER_COUNT_Payload,
  SUBSCRIBE_USER_TRANSACTIONS: {} as SUBSCRIBE_USER_TRANSACTIONS_Payload,
  UNSUBSCRIBE_USER_TRANSACTIONS: undefined,
  UPDATE_USERS_TRANSACTIONS_RESOLVED:
    {} as UPDATE_USERS_TRANSACTIONS_RESOLVED_Payload,
  GET_USER_TRANSACTION_DOWNLOAD_URL:
    {} as GET_USER_TRANSACTION_DOWNLOAD_URL_Payload,
  UPDATE_USER_TABLE_COLUMNS: {} as UPDATE_USER_TABLE_COLUMNS_Payload,
  GET_USER_TABLE_COLUMNS: {} as GET_USER_TABLE_COLUMNS_Payload,
  UPDATE_USER_CONFIRMED_STATUS: {} as UPDATE_USER_CONFIRMED_STATUS_Payload,
  UPDATE_COUNT_CONFIRMED_STATUS: {} as UPDATE_COUNT_CONFIRMED_STATUS_Payload,
  DISMISS_ALL_USER_COUNT: {} as DISMISS_ALL_USER_COUNT_Payload,
  SUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS: undefined,
  UNSUBSCRIBE_ISSUE_LOGS_TAG_SUGGESTIONS: undefined,
  SUBSCRIBE_USER_STATUS_NOTE_SUGGESTIONS: undefined,
  UNSUBSCRIBE_USER_STATUS_NOTE_SUGGESTIONS: undefined,
  SUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS: undefined,
  UNSUBSCRIBE_REPORT_GUIDELINES_NOTE_SUGGESTIONS: undefined,
  SUBSCRIBE_INVOICE_NOTE_SUGGESTIONS: undefined,
  UNSUBSCRIBE_INVOICE_NOTE_SUGGESTIONS: undefined,
  SUBSCRIBE_TASK_NOTE_SUGGESTIONS: undefined,
  UNSUBSCRIBE_TASK_NOTE_SUGGESTIONS: undefined,
  UPDATE_NOTE_SUGGESTIONS: {} as UPDATE_NOTE_SUGGESTIONS_Payload,
  SUBSCRIBE_TO_SELECTED_USERID: {} as SUBSCRIBE_TO_SELECTED_USERID_payload,
  UNSUBSCRIBE_TO_SELECTED_USERID: undefined,
  SUBSCRIBE_TO_ONBOARDING_USERS: undefined,
  UNSUBSCRIBE_FROM_ONBOARDING_USERS: undefined,
  ADD_ACTIVITY_NOTE: {} as ADD_ACTIVITY_NOTE_Payload,
  UPDATE_ACTIVITY_NOTE: {} as UPDATE_ACTIVITY_NOTE_Payload,
  DELETE_ACTIVITY_NOTE: {} as DELETE_ACTIVITY_NOTE_Payload,
  SUBSCRIBE_TO_ACTIVITIES: {} as SUBSCRIBE_TO_ACTIVITIES_payload,
  EDIT_NOTE_SUGGESTIONS: {} as EDIT_NOTE_SUGGESTIONS_Payload,
  UNSUBSCRIBE_TO_ACTIVITIES: undefined,
});

export default actions;
