import { createActions } from "redux/create-actions";
import {
  GET_DAILY_SALES_payload,
  GET_USER_SALES_payload,
  SUBSCRIBE_TO_USER_DAILY_SALES_payload,
  SUBSCRIBE_USER_SALES_payload,
  SUBSCRIBE_USER_WASTAGES_payload,
  UPDATE_SALES_DATA_Payload,
} from "./sagas";
import { SalesState } from "./types";

const actions = createActions("sales", {
  SET_STATE: {} as Partial<SalesState>,
  GET_USER_SALES: {} as GET_USER_SALES_payload,
  UPDATE_SALES_DATA: {} as UPDATE_SALES_DATA_Payload,
  GET_DAILY_SALES: {} as GET_DAILY_SALES_payload,
  SUBSCRIBE_USER_SALES: {} as SUBSCRIBE_USER_SALES_payload,
  UNSUBSCRIBE_USER_SALES: undefined,
  SUBSCRIBE_TO_USER_DAILY_SALES: {} as SUBSCRIBE_TO_USER_DAILY_SALES_payload,
  UNSUBSCRIBE_FROM_USER_DAILY_SALES: undefined,
  SUBSCRIBE_USER_WASTAGES: {} as SUBSCRIBE_USER_WASTAGES_payload,
  UNSUBSCRIBE_USER_WASTAGES: undefined,
});

export default actions;
