import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { ScriptState } from "./types";

const initialState: ScriptState = {
  loadingGetCounts: false,
  userCounts: [],
  userCountAreas: [],
  otp: {},
  loadingOtp: false,
  otpMessages: {},
  loadingOtpMessages: false,
  loadingGetCountAreas: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
