import { Button, Dialog, H4, Icon, InputGroup } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { useEffect, useMemo, useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import settingsActions from "redux/settings/actions";
import { User } from "redux/users/types";
import style from "./index.module.scss";

interface AddDefaultFlagProps {
  fields: Map<string, string>;
}

type Field = {
  key: string;
  type: string;
  isExist: boolean;
};

const DEFAULT_FILTERED_FIELDS = ["id", "isOnline", "refPath"];

function AddDefaultFlag({ fields }: AddDefaultFlagProps) {
  const dispatch = useAppDispatch();
  const defaultUserProfile = useAppSelector(
    (state) => state.settings.defaultUserProfile
  );
  const loadingSave = useAppSelector((state) => state.settings.loadingSave);

  const [isOpen, setIsOpen] = useState(false);
  const [showExistingFlags, setShowExistingFlags] = useState(false);
  const [flagsToAdd, setFlagsToAdd] = useState<Field[]>([]);
  const [flagQuery, setFlagQuery] = useState<string>("");
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);

  const parsedFields = useMemo(() => {
    const parsedAttrMaps = Array.from(fields.entries())
      .map(([key, type]) => {
        return {
          key,
          type,
          isExist: defaultUserProfile?.hasOwnProperty(key),
        };
      })
      .sort((a, b) => a.key.localeCompare(b.key))
      .filter((attr) => !DEFAULT_FILTERED_FIELDS.includes(attr.key));

    return parsedAttrMaps;
  }, [fields, defaultUserProfile]);

  useEffect(() => {
    if (!flagQuery) {
      setFilteredFields(
        showExistingFlags
          ? parsedFields
          : parsedFields.filter((field) => !field.isExist)
      );
      return;
    }

    const result = parsedFields.filter((field) => {
      const filterExistingFlags = showExistingFlags
        ? field.isExist
        : !field.isExist;
      return (
        field.key.toLowerCase().includes(flagQuery.toLowerCase()) &&
        filterExistingFlags
      );
    });
    setFilteredFields(result);
  }, [flagQuery, parsedFields, showExistingFlags]);

  const handleSaveNewFlags = () => {
    if (flagsToAdd.length === 0) {
      dispatch(alertActions.ERROR("No flags to add"));
      return;
    }

    const payload: Partial<User> = {};
    const getInitValue = (type: string) => {
      switch (type) {
        case "string":
          return "";
        case "number":
          return 0;
        case "boolean":
          return false;
        case "object":
          return {};
        default:
          return "";
      }
    };

    for (const flag of flagsToAdd) {
      payload[flag.key] = getInitValue(flag.type);
    }

    dispatch(
      settingsActions.UPDATE_DEFAULT_USER_PROFILE({
        newDefaultUserProfile: payload,
      })
    );

    setFlagsToAdd([]);
  };

  return (
    <>
      <Button
        icon="flag"
        text="Add default flag"
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        title="Add default flag"
        icon="flag"
        style={{ width: 600 }}
      >
        <>
          <div className={style.container}>
            <div className={style.section_header}>
              <InputGroup
                placeholder="Search for field name..."
                value={flagQuery}
                onChange={(e) => setFlagQuery(e.target.value)}
              />
              <Button
                text={
                  showExistingFlags
                    ? "Hide existing flags"
                    : "Show existing flags"
                }
                onClick={() => setShowExistingFlags(!showExistingFlags)}
              />
            </div>
            <div className={cn(style.field_list, style.field_list__scrollable)}>
              {filteredFields.map((field) => {
                return (
                  <div
                    className={cn(
                      style.field_item,
                      style.field_item__clickable,
                      flagsToAdd.find((flag) => flag.key === field.key)
                        ? style.field_item__selected
                        : ""
                    )}
                    key={field.key}
                    onClick={() => {
                      if (field.isExist) return;
                      if (flagsToAdd.find((flag) => flag.key === field.key)) {
                        setFlagsToAdd((prev) =>
                          prev.filter((flag) => flag.key !== field.key)
                        );
                        return;
                      }
                      setFlagsToAdd((prev) => [...prev, field]);
                    }}
                  >
                    <div>
                      <span>{field.key}</span>
                      {field.isExist && (
                        <span style={{ fontSize: "10px", color: "#d98cfa" }}>
                          {" "}
                          (Exists)
                        </span>
                      )}
                    </div>
                    <span>{field.type}</span>
                  </div>
                );
              })}
            </div>
            <div className={style.section_header}>
              <H4>Flags to add : </H4>
            </div>
            {flagsToAdd.length > 0 ? (
              <div className={style.field_list}>
                {flagsToAdd.map((flag) => {
                  return (
                    <div className={style.field_item} key={flag.key}>
                      <div>
                        <span>{flag.key}</span>{" "}
                        <span style={{ fontSize: "10px", color: "#face55" }}>
                          <i>New</i>
                        </span>
                      </div>
                      <div>
                        <span style={{ marginRight: "1rem" }}>{flag.type}</span>
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="trash"
                          intent="danger"
                          onClick={() =>
                            setFlagsToAdd((prev) =>
                              prev.filter((x) => x.key !== flag.key)
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <span>No flags to add</span>
            )}
          </div>
          <div className={style.dialog_footer}>
            <Button
              text="Submit"
              intent="danger"
              disabled={flagsToAdd.length === 0}
              loading={loadingSave}
              onClick={() => handleSaveNewFlags()}
            />
          </div>
        </>
      </Dialog>
    </>
  );
}

export default AddDefaultFlag;
