import { createReducer } from "@reduxjs/toolkit";
import actions from "redux/prompts/actions";
import { PromptsState } from "./types";

export const initialState: PromptsState = {
  loading: false,
  geminiPrompts: [],
  loadingAdd: false,
  loadingUpdate: false,
  activeSupplierPrompts: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
