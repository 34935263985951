import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./index.scss";
import { Button, Tooltip } from "@blueprintjs/core";
import Alert from "components/alert";
import React from "react";
import Split from "react-split";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import invoiceActions from "../redux/invoice/actions";
import {
  useInvoiceFloatingButton,
  useInvoiceShortcutGuideButton,
  useSidePanel,
} from "./hooks";
import LayoutOutlet from "./outlet";
import SidePanel from "./side-panel";

type SearchProps = {
  value: string;
  placeholder: string;
  readOnly: boolean;
};

export type UseSearchContext = {
  searchProps: SearchProps;
  setSearchProps: React.Dispatch<React.SetStateAction<SearchProps>>;
  focus: () => void;
};

function Layout() {
  const dispatch = useAppDispatch();
  const isInvoiceFloatingButtonsVisible = useAppSelector(
    (state) => state.invoices.isFloatingButtonsVisible
  );
  const isShortcutGuideButtonVisible = useAppSelector(
    (state) => state.invoices.isShortcutGuideButtonVisible
  );
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const sidePanelActive = useSidePanel();
  const invoiceFloatingButtonsActive = useInvoiceFloatingButton();
  const invoiceShortcutGuideActive = useInvoiceShortcutGuideButton();
  const [mainSize, setMainSize] = useState<number>(100);
  const [panelSize, setPanelSize] = useState<number>(0);

  useEffect(() => {
    if (!isPanelOpen) {
      document.body.style.overflowX = "hidden";
      setMainSize(100);
      setPanelSize(0);
    } else {
      document.body.style.overflowX = "auto";

      setMainSize(70);
      setPanelSize(30);
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (!sidePanelActive) {
      setIsPanelOpen(false);
      setMainSize(100);
      setPanelSize(0);
    }
  }, [sidePanelActive]);

  return (
    <>
      <Alert />
      <Split
        className={!isPanelOpen ? "no-drag" : undefined}
        direction="horizontal"
        cursor="col-resize"
        sizes={[mainSize, panelSize]}
        minSize={0}
        expandToMin={false}
        gutterSize={isPanelOpen ? 8 : 0}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        style={{ width: "100%", display: "flex" }}
        onDrag={(sizes) => {
          if (!sidePanelActive) return;
          setMainSize(sizes[0]);
          setPanelSize(sizes[1]);
          if (sizes[1] < 1) setIsPanelOpen(false);
          else setIsPanelOpen(true);
        }}
      >
        <LayoutOutlet />
        {isPanelOpen ? <SidePanel /> : <div></div>}
      </Split>

      {sidePanelActive && (
        <Tooltip content="Toggle Side Panel" position="left">
          <Button
            className="split-button"
            icon="two-columns"
            onClick={() => {
              setIsPanelOpen(!isPanelOpen);
            }}
          />
        </Tooltip>
      )}

      {invoiceFloatingButtonsActive && (
        <Tooltip content="Toggle Invoice Floating Buttons [alt+3]">
          <Button
            className="invoice-floating-buttons-toggle-button"
            icon={isInvoiceFloatingButtonsVisible ? "eye-open" : "eye-off"}
            onClick={() => {
              dispatch(
                invoiceActions.SET_STATE({
                  isFloatingButtonsVisible: !isInvoiceFloatingButtonsVisible,
                })
              );
            }}
          />
        </Tooltip>
      )}

      {invoiceShortcutGuideActive && (
        <Tooltip content="Toggle Invoice Shortcut Guide">
          <Button
            className="shortcut-guide-button"
            icon={"manual"}
            onClick={() => {
              dispatch(
                invoiceActions.SET_STATE({
                  isShortcutGuideButtonVisible: !isShortcutGuideButtonVisible,
                })
              );
            }}
          />
        </Tooltip>
      )}
    </>
  );
}

export function useSearch() {
  return useOutletContext<UseSearchContext>();
}

export default Layout;
