import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { safeTimestamp } from "utils/common";

export type SalesState = {
  loadingSales: boolean;
  loadingSalesRefs: boolean;
  sales: MunuFile[];
  firstSaleId: string | null;
  lastSaleId: string | null;
  salesRefs: string[];
  dailySale: DailySale | null;
  loadingDailySales: boolean;
  notDeletedSales: MunuFile[];
  userDailySales: {
    firstDoc?: QueryDocumentSnapshot<DocumentData>;
    lastDoc?: QueryDocumentSnapshot<DocumentData>;
    data: DailySale[];
  };
  loadingUserDailySales: boolean;
  loadingWastages: boolean;
  wastages: WastageFile[];
  notDeletedWastages: WastageFile[];
  loadingDelete: boolean;
};

export class DailySale {
  data: SaleSheet[];
  date: Date;
  updatedAt: Date | null;
  munuFileId: string;
  userId: string;
  deleted: boolean;

  constructor(data: DocumentData) {
    this.data = JSON.parse(data.data).slice(1);
    this.date = safeTimestamp(data.date)?.toDate() ?? new Date();
    this.updatedAt = safeTimestamp(data.updatedAt)?.toDate() ?? null;
    this.munuFileId = data.munuFileId;
    this.userId = data.userId;
    this.deleted = data.deleted ?? false;
  }
}

export type SaleSheet = [string, number, number, string, number];

export class MunuFile {
  id: string;
  createdAt: Timestamp;
  data: string;
  periodEnd: Timestamp;
  periodStart: Timestamp;
  userId: string;
  deleted: boolean;
  vendor: string;
  clientName?: string;
  department?: string;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.createdAt = data.createdAt ?? data.periodEnd;
    this.data = data.data;
    this.periodEnd = data.periodEnd;
    this.periodStart = data.periodStart;
    this.userId = data.userId;
    this.deleted = data.deleted ?? false;
    this.vendor = data.vendor ?? "";
    this.clientName = data.clientName;
    this.department = data.department;
  }
}

export class WastageFile {
  id: string;
  createdAt: Timestamp;
  data: { name: string; quantity: number }[];
  periodEnd: Timestamp;
  periodStart: Timestamp;
  userId: string;
  deleted: boolean;
  vendor: string;
  clientName?: string;
  department?: string;

  constructor(id: string, data: DocumentData, userIntegration: string) {
    this.id = id;
    this.createdAt = data.createdAt ?? data.periodEnd;
    this.data = data.data;
    this.periodEnd = data.periodEnd;
    this.periodStart = data.periodStart;
    this.userId = data.userId;
    this.deleted = data.deleted ?? false;
    this.vendor = data.vendor ?? `${userIntegration}_Wastage`;
    this.clientName = data.clientName;
    this.department = data.department;
  }
}
