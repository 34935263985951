import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel, eventChannel } from "redux-saga";
import { APTransaction } from "redux/ap-transactions/types";
import { IssueLogTagSuggestion, User, UserActivity } from "redux/users/types";
import { db, isLocalhost } from "services/firebase";
import { v4 } from "uuid";

export function setUsersListener(
  loadAllUsers: boolean = false
): EventChannel<User[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const activeUsersCondition = [
      where("active", "==", true),
      where("organization", "==", false),
      where("employee", "==", false),
    ];
    const queryConstraints = [
      ...(loadAllUsers ? [] : activeUsersCondition),
      orderBy("name"),
    ];
    const userQuery = query(collection(db, "users"), ...queryConstraints);
    const unsub = onSnapshot(
      userQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const users = snapshot.docs.map(
          (doc) => new User(doc.id, doc.ref.path, doc.data())
        );
        emitter(users);
      }
    );

    return () => unsub();
  });
}

export function setOnboardingUsersListener(): EventChannel<User[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const queryConstraints = [
      where("isOnboardingTutorialsTabEnabled", "==", true),
      where("active", "==", true),
      where("demo", "==", true),
      orderBy("name"),
    ];

    const userQuery = query(collection(db, "users"), ...queryConstraints);
    const unsub = onSnapshot(
      userQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const users = snapshot.docs.map(
          (doc) => new User(doc.id, doc.ref.path, doc.data())
        );
        emitter(users);
      }
    );

    return () => unsub();
  });
}

export function setSelectedUserListener(
  userId: string
): EventChannel<User | null> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const userDocRef = doc(db, "users", userId);
    const unsub = onSnapshot(userDocRef, (doc) => {
      let user = null;
      const data = doc.data();
      if (data) {
        user = new User(doc.id, doc.ref.path, data);
      }
      emitter(user);
    });

    return () => unsub();
  });
}

export function setUserTransactionsListener(
  userId: string,
  targetDate?: Date
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints: QueryConstraint[] = [
      where("deleted", "==", false),
      orderBy("createdAt", "desc"),
    ];

    if (targetDate) {
      const limitDate = moment(targetDate).subtract(61, "day").toDate();
      constraints.push(where("createdAt", ">=", limitDate));
      constraints.push(where("createdAt", "<=", targetDate));
    } else {
      const limitDate = moment().subtract(61, "day").toDate();
      constraints.push(where("createdAt", ">=", limitDate));
    }
    if (isLocalhost) constraints.push(limit(400));

    const userTransactionsQuery = query(
      collection(db, `users/${userId}/invoiceTransactions`),
      ...constraints
    );
    const unsub = onSnapshot(
      userTransactionsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const userTransactions: APTransaction[] = [];

        for (const doc of snapshot.docs) {
          const current = new APTransaction(doc.id, doc.ref, doc.data());
          const existingTransaction = userTransactions.find(
            (x) => x.invoiceNumber && x.invoiceNumber === current.invoiceNumber
          );

          if (existingTransaction) {
            const combinedFiles = new Set<string>([
              ...(existingTransaction.files || []),
              ...(existingTransaction.path ? [existingTransaction.path] : []),
              ...(current.files || []),
              ...(current.path ? [current.path] : []),
            ]);

            existingTransaction.files = Array.from(combinedFiles);
          } else userTransactions.push(current);
        }

        emitter(userTransactions);
      }
    );

    return () => unsub();
  });
}

export function setUserStatusTransactionsListener(
  userId: string,
  resolved = false,
  maxResolvedTransactions = 15
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 61);

    const constraints: QueryConstraint[] = [
      where("createdAt", ">=", newDate),
      where("deleted", "==", false),
      orderBy("createdAt", "desc"),
    ];

    if (resolved) {
      constraints.push(where("resolved", "==", true));
      constraints.push(limit(maxResolvedTransactions));
    } else constraints.push(where("resolved", "==", false));

    const userTransactionsQuery = query(
      collection(db, `users/${userId}/invoiceTransactions`),
      ...constraints
    );
    const unsub = onSnapshot(
      userTransactionsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const userTransactions: APTransaction[] = [];

        for (const doc of snapshot.docs) {
          const current = new APTransaction(doc.id, doc.ref, doc.data());
          const existingTransaction = userTransactions.find(
            (x) => x.invoiceNumber && x.invoiceNumber === current.invoiceNumber
          );

          if (existingTransaction) {
            const combinedFiles = new Set<string>([
              ...(existingTransaction.files || []),
              ...(existingTransaction.path ? [existingTransaction.path] : []),
              ...(current.files || []),
              ...(current.path ? [current.path] : []),
            ]);

            existingTransaction.files = Array.from(combinedFiles);
          } else userTransactions.push(current);
        }

        emitter(userTransactions);
      }
    );

    return () => unsub();
  });
}

export function setNoteSuggestions(
  docName: string
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(doc(db, "adminPanelSettings", docName), (doc) => {
      const noteSuggestions: DocumentData[] = doc.data()?.data ?? [];

      emitter(noteSuggestions);
    });

    return () => unsub();
  });
}

export function setIssueLogTagSuggestions(): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      doc(db, "adminPanelSettings", "issueLogTagSuggestions"),
      (doc) => {
        const tagSuggestions: DocumentData[] = doc.data()?.data.length
          ? doc.data()?.data.map((x: string | IssueLogTagSuggestion) => {
              if (typeof x === "string") {
                return { key: v4(), tagLabel: x, color: "" };
              } else return x;
            })
          : [];

        emitter(tagSuggestions);
      }
    );

    return () => unsub();
  });
}

export function setUserActivitiesListener(
  userId: string,
  countId: string
): EventChannel<UserActivity[] | null> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const activitiesRef = collection(doc(db, "users", userId), "activities");
    const activitiesQuery = query(
      activitiesRef,
      where("deleted", "==", false),
      where("countId", "==", countId),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(activitiesQuery, (snapshot) => {
      const data = snapshot.docs.map(
        (doc) =>
          ({
            activityId: doc.id,
            ...doc.data(),
          }) as UserActivity
      );

      emitter(data);
    });

    return () => unsub();
  });
}
