import { createActions } from "redux/create-actions";
import {
  CREATE_GEMINI_PROMPT_Payload,
  UPDATE_GEMINI_PROMPT_Payload,
} from "./sagas";
import { PromptsState } from "./types";

const actions = createActions("prompts", {
  SET_STATE: {} as Partial<PromptsState>,
  SUBSCRIBE_TO_GEMINI_PROMPTS: undefined,
  UNSUBSCRIBE_FROM_GEMINI_PROMPTS: undefined,
  CREATE_GEMINI_PROMPT: {} as CREATE_GEMINI_PROMPT_Payload,
  UPDATE_GEMINI_PROMPT: {} as UPDATE_GEMINI_PROMPT_Payload,
  GET_ACTIVE_SUPPLIER_PROMPTS: undefined,
});

export default actions;
