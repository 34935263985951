import {
  Button,
  Callout,
  Dialog,
  DialogBody,
  DialogFooter,
  HTMLSelect,
  Icon,
  InputGroup,
  NumericInput,
  Popover,
} from "@blueprintjs/core";
import { DatePicker3 } from "@blueprintjs/datetime2";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
import AssignTutorialChecklist from "components/users/assign-tutorial-checklist";
import EditHiddenUser from "components/users/edit-hidden-user";
import { httpsCallable } from "firebase/functions";
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import countsActions from "redux/counts/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import supplierActions from "redux/suppliers/actions";
import { EditAttributeDialogRef } from "redux/types";
import usersActions from "redux/users/actions";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";

type PriorityOption = {
  value: number;
  label: string;
};

type AccessLvlOption = {
  value: number;
  label: string;
};

type OrgOption = {
  value: string;
  label: string;
};

type hiddenUser = {
  id: string;
  name: string;
};

const EditAttributeDialog = forwardRef(
  (
    props: {
      attrMap: any;
      attributeTarget: string;

      attributeValue: any;
      id: string;
      module: string;

      countRef?: any;
    },
    ref: Ref<EditAttributeDialogRef>
  ) => {
    const { attrMap, attributeTarget, attributeValue, id, module, countRef } =
      props;
    const dispatch = useAppDispatch();
    const users = useAppSelector((state) => state.users.users);
    const loadingUserUpdate = useAppSelector(
      (state) => state.users.loadingUpdate
    );
    const accessLevels = useAppSelector(
      (state) => state.accessLevels.accessLevels
    );
    const userPriorities = useAppSelector(
      (state) => state.userPriorities.userPriorities
    );
    const loadingCountUpdate = useAppSelector(
      (state) => state.counts.loadingUpdate
    );
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

    const [newAttributeValue, setNewAttributeValue] = useState<any>(null);
    const [hiddenUsers, setHiddenUsers] = useState<hiddenUser[]>([]);
    const [isFirstRender, setFirstRender] = useState<boolean>(true);

    useEffect(() => {
      if (!isFirstRender) return;
      if (
        (module === "counts" &&
          (attributeTarget === "startTime" || attributeTarget === "endTime")) ||
        (module === "users" && attributeTarget === "openPeriods")
      ) {
        setNewAttributeValue(attributeValue);
        setFirstRender(false);
      }
    }, [module, attributeTarget, attributeValue, isFirstRender]);

    const accessLevelOptions: AccessLvlOption[] = (() => {
      const data = [];
      for (const key in accessLevels) {
        if (key !== "0")
          data.push({
            value: parseInt(key),
            label: parseInt(key) + " - " + accessLevels[key],
          });
      }
      return data;
    })();

    const fallback = { 1: "", 2: "", 3: "", 4: "", 5: "" };
    const priorityOptions: PriorityOption[] = (() => {
      const data = [];
      for (const key in !userPriorities ? userPriorities : fallback) {
        data.push({
          value: parseInt(key),
          label:
            userPriorities[parseInt(key)] === undefined
              ? parseInt(key) + " - "
              : parseInt(key) + " - " + userPriorities[parseInt(key)],
        });
      }
      return data;
    })();

    const orgOptions: OrgOption[] = [
      { label: "Select Organization...", value: "" },
      ...users
        .filter((user) => user.organization)
        .map((org) => ({
          label: org.name,
          value: org.id,
        })),
    ];

    useEffect(() => {
      const currentUser = users.filter((user) => user.id === id)[0];
      if (currentUser?.hiddenUserIds) {
        const existedData = users
          .filter((user) => currentUser?.hiddenUserIds.includes(user.id))
          .map((user) => ({ id: user.id, name: user.name }));
        setHiddenUsers(existedData);
      }
    }, [users]);

    const stateOptions = [
      { label: "Started", value: "started" },
      { label: "Pending", value: "pending" },
      { label: "Complete", value: "complete" },
    ];

    const dispatchUpdateUser = (customFields?: Record<string, unknown>) => {
      dispatch(
        usersActions.UPDATE_USER({
          user: customFields ?? {
            [attributeTarget]: newAttributeValue,
          },
          userId: id,
        })
      );
    };

    const handleUpdateDatum = () => {
      if (module === "users") {
        if (
          attributeTarget === "active" &&
          attributeValue !== newAttributeValue
        ) {
          if (newAttributeValue === true) {
            if (!functions) return console.log("functions is null");
            const activateUser = httpsCallable(
              functions,
              CALLABLE_FUNCTIONS.enableLogin
            );
            activateUser({ uid: id })
              .then(() => dispatchUpdateUser())
              .catch((err) => console.log("error", err));
          } else if (newAttributeValue === false) {
            if (!functions) return console.log("functions is null");
            const deactivateUser = httpsCallable(
              functions,
              CALLABLE_FUNCTIONS.disableLogin
            );
            deactivateUser({ uid: id })
              .then(() => dispatchUpdateUser())
              .catch((err) => console.log("error", err));
          }
        } else if (attributeTarget === "organizationName") {
          const organizationName = orgOptions.find(
            (o) => o.value === newAttributeValue
          )?.label;
          dispatchUpdateUser({
            organizationName: organizationName,
            organizationId: newAttributeValue,
          });
        } else {
          dispatchUpdateUser();
        }
      } else if (module === "counts") {
        dispatch(
          countsActions.UPDATE_COUNT({
            data: {
              [attributeTarget]: newAttributeValue,
            },
            countRef: countRef,
          })
        );
      } else if (module === "suppliers") {
        dispatch(
          supplierActions.UPDATE_SUPPLIER({
            id: id,
            supplier: {
              [attributeTarget]: newAttributeValue,
            },
          })
        );
      }

      setTimeout(() => {
        setEditDialogOpen(false);
      }, 1000);
    };

    const handleOpenDialog = useCallback(() => {
      setEditDialogOpen(true);
    }, []);

    const handleCancelUpdate = useCallback(() => {
      if (
        (module === "counts" &&
          (attributeTarget === "startTime" || attributeTarget === "endTime")) ||
        (module === "users" && attributeTarget === "openPeriods")
      ) {
        setNewAttributeValue(attributeValue);
      } else {
        setNewAttributeValue(null);
      }
      setEditDialogOpen(false);
    }, [module, attributeTarget, attributeValue]);

    useImperativeHandle(ref, () => {
      return {
        handleOpenDialog: handleOpenDialog,
      };
    });

    const handleEmptyOutFields = (fields: string[]) => {
      const newUserState: { [key: string]: string } = {};
      fields.forEach((field) => {
        newUserState[field] = "";
      });

      dispatch(
        usersActions.UPDATE_USER({
          user: newUserState,
          userId: id,
        })
      );
      setEditDialogOpen(false);
    };

    const handleDeletePeriod = (idx: number) => {
      const newPeriods = [...newAttributeValue];
      newPeriods.splice(idx, 1);
      setNewAttributeValue(newPeriods);
    };

    const renderEditAttributeValue = () => {
      if (attributeTarget === "accessLevel") {
        return (
          <HTMLSelect
            fill
            options={accessLevelOptions}
            onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
            defaultValue={attributeValue}
          >
            <option value="" disabled>
              Select your option
            </option>
          </HTMLSelect>
        );
      } else if (attributeTarget === "priority") {
        return (
          <HTMLSelect
            fill
            options={priorityOptions}
            onChange={(e) =>
              setNewAttributeValue(parseInt(e.currentTarget.value))
            }
            defaultValue={attributeValue}
          >
            <option value="" disabled>
              Select your option
            </option>
          </HTMLSelect>
        );
      } else if (attributeTarget === "organizationName") {
        return (
          <>
            <HTMLSelect
              fill
              options={orgOptions}
              onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
              defaultValue={attributeValue}
            >
              <option value="" disabled>
                Select your option
              </option>
            </HTMLSelect>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1em",
              }}
            >
              <ActionConfirmationDialogue
                onConfirm={() =>
                  handleEmptyOutFields(["organizationName", "organizationId"])
                }
                title={"Remove Organization"}
                text={
                  "Are you sure you want to remove these users from their organization?"
                }
                actionName={"Confirm"}
              >
                <span style={{ color: "#fd7afe", cursor: "pointer" }}>
                  Remove Organization
                </span>
              </ActionConfirmationDialogue>
            </div>
          </>
        );
      } else if (attributeTarget === "nameForCloseAPI") {
        return (
          <>
            <InputGroup
              fill
              onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
              defaultValue={attributeValue}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1em",
              }}
            >
              <ActionConfirmationDialogue
                onConfirm={() => handleEmptyOutFields(["nameForCloseAPI"])}
                title={"Remove nameForCloseAPI"}
                text={"Are you sure you want to remove the name for Close API?"}
                actionName={"Confirm"}
              >
                <span style={{ color: "#fd7afe", cursor: "pointer" }}>
                  Remove Name For Close API
                </span>
              </ActionConfirmationDialogue>
            </div>
          </>
        );
      } else if (attributeTarget === "organizationId") {
        return (
          <>
            <InputGroup
              fill
              onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
              defaultValue={attributeValue}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1em",
              }}
            >
              <ActionConfirmationDialogue
                onConfirm={() =>
                  handleEmptyOutFields(["organizationName", "organizationId"])
                }
                title={"Remove Organization"}
                text={
                  "Are you sure you want to remove these users from their organization?"
                }
                actionName={"Confirm"}
              >
                <span style={{ color: "#fd7afe", cursor: "pointer" }}>
                  Remove Organization
                </span>
              </ActionConfirmationDialogue>
            </div>
          </>
        );
      } else if (attributeTarget === "state") {
        return (
          <HTMLSelect
            fill
            options={stateOptions}
            onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
            defaultValue={attributeValue}
          >
            <option value="" disabled>
              Select your option
            </option>
          </HTMLSelect>
        );
      } else if (attributeTarget === "hiddenUserIds") {
        return (
          <div style={{ width: 250 }}>
            <EditHiddenUser
              hiddenUsers={hiddenUsers}
              setHiddenUsers={setHiddenUsers}
              data={getUsersIdName()}
            />
          </div>
        );
      } else if (attributeTarget === "tutorialChecklistId") {
        return (
          <AssignTutorialChecklist
            onSelect={(checklist) => setNewAttributeValue(checklist.id)}
            selectedValue={attributeValue}
          />
        );
      } else if (
        module === "counts" &&
        (attributeTarget === "startTime" || attributeTarget === "endTime")
      ) {
        return (
          <div>
            <DatePicker3
              value={new Date(newAttributeValue)}
              onChange={(e) => setNewAttributeValue(e?.getTime())}
            />
          </div>
        );
      } else if (module === "users" && attributeTarget === "openPeriods") {
        return (
          <>
            {Array.isArray(newAttributeValue) &&
              newAttributeValue?.map(
                (period: { from: string; to: string }, idx: number) => {
                  return (
                    <div
                      key={`period-${idx}`}
                      style={{
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexBasis: "100%",
                          marginRight: 5,
                        }}
                        key={`period-${idx}`}
                      >
                        <Popover
                          content={
                            <DatePicker3
                              minDate={new Date(new Date().getFullYear(), 0, 1)}
                              maxDate={
                                new Date(new Date().getFullYear(), 11, 31)
                              }
                              onChange={(date) => {
                                if (!date) return;
                                const newPeriods = newAttributeValue.map(
                                  (
                                    x: { from: string; to: string },
                                    index: number
                                  ) => {
                                    if (idx === index) {
                                      return {
                                        ...x,
                                        from: `${date.getDate()} ${date.toLocaleString(
                                          "default",
                                          { month: "short" }
                                        )}`,
                                      };
                                    }
                                    return x;
                                  }
                                );
                                setNewAttributeValue(newPeriods);
                              }}
                            />
                          }
                          className="w-100"
                        >
                          <Button className="w-100">
                            {period?.from || "From"}
                          </Button>
                        </Popover>
                        <Icon icon="arrow-right" style={{ margin: "0 20px" }} />
                        <Popover
                          content={
                            <DatePicker3
                              minDate={new Date(new Date().getFullYear(), 0, 1)}
                              maxDate={
                                new Date(new Date().getFullYear(), 11, 31)
                              }
                              onChange={(date) => {
                                if (!date) return;
                                const newPeriods = newAttributeValue.map(
                                  (
                                    x: { from: string; to: string },
                                    index: number
                                  ) => {
                                    if (idx === index) {
                                      return {
                                        ...x,
                                        to: `${date.getDate()} ${date.toLocaleString(
                                          "default",
                                          { month: "short" }
                                        )}`,
                                      };
                                    }
                                    return x;
                                  }
                                );
                                setNewAttributeValue(newPeriods);
                              }}
                            />
                          }
                          className="w-100"
                        >
                          <Button className="w-100">
                            {period?.to || "To"}
                          </Button>
                        </Popover>
                      </div>
                      <Button
                        icon="trash"
                        minimal
                        onClick={() => handleDeletePeriod(idx)}
                      />
                    </div>
                  );
                }
              )}
          </>
        );
      } else if (
        attributeTarget === "language" ||
        attributeTarget === "locale"
      ) {
        const options = [
          { label: "en", value: "en" },
          { label: "no", value: "no" },
          { label: "hr", value: "hr" },
        ];
        return (
          <HTMLSelect
            fill
            options={options}
            onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
            defaultValue={attributeValue?.toString() ?? ""}
          >
            <option value="" disabled>
              Select your option
            </option>
          </HTMLSelect>
        );
      } else if (attrMap.get(attributeTarget) === "number") {
        return (
          <NumericInput
            fill
            placeholder={attributeTarget}
            defaultValue={attributeValue}
            name={attributeTarget}
            min={0}
            onValueChange={(valueAsNumber: number) =>
              setNewAttributeValue(valueAsNumber)
            }
          />
        );
      } else if (attrMap.get(attributeTarget) === "string") {
        return (
          <InputGroup
            fill
            placeholder={attributeTarget}
            defaultValue={attributeValue}
            name={attributeTarget}
            onChange={(e) => setNewAttributeValue(e.currentTarget.value)}
          />
        );
      } else if (attrMap.get(attributeTarget) === "boolean") {
        const options = [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ];
        return (
          <HTMLSelect
            fill
            options={options}
            onChange={(e) =>
              setNewAttributeValue(e.currentTarget.value === "true")
            }
            defaultValue={attributeValue?.toString() ?? ""}
          >
            <option value="" disabled>
              Select your option
            </option>
          </HTMLSelect>
        );
      }
    };

    const getUser = useCallback(
      (userId: string) => {
        const user = users.find((x) => x.id === userId);
        return user ? user.name : userId;
      },
      [users]
    );

    const getUsersIdName = useCallback(() => {
      return users
        .filter((user) => user.id !== id)
        .map((user) => ({ id: user.id, name: user.name }));
    }, [users]);

    return (
      <>
        <Dialog
          isOpen={editDialogOpen}
          onClose={handleCancelUpdate}
          autoFocus
          enforceFocus
          className="pb-0"
          icon="edit"
          title={`Edit Attribute`}
        >
          <DialogBody>
            <Callout title="Warning" intent="warning" className="mb-3">
              Note that you're editing attribute{" "}
              <code
                style={{ backgroundColor: "#3E342C", padding: "0.2em 0.5em" }}
              >
                {attributeTarget}
              </code>{" "}
              for{" "}
              <code
                style={{ backgroundColor: "#3E342C", padding: "0.2em 0.5em" }}
              >
                {module === "users" ? getUser(id) : id}
              </code>
              .
            </Callout>
            <div
              className={`${
                attributeTarget === "openPeriods" ? "" : "d-flex"
              } align-items-center justify-content-between`}
            >
              <p
                className={`${
                  attributeTarget === "openPeriods" ? "mb-2" : "mb-0"
                }`}
              >
                {attributeTarget}:
              </p>
              <div>{renderEditAttributeValue()}</div>
              {attributeTarget === "openPeriods" && (
                <Button
                  minimal
                  className="w-100 mt-2"
                  onClick={() => {
                    setNewAttributeValue([
                      ...(newAttributeValue || []),
                      { from: "", to: "" },
                    ]);
                  }}
                  text="Add Open Period"
                />
              )}
            </div>
          </DialogBody>

          <DialogFooter
            actions={[
              <Button
                onClick={handleCancelUpdate}
                text="Cancel"
                key={`cancel__btn`}
              />,
              attributeTarget === "active" ? (
                <InputActionConfirmationDialogue
                  hasDoubleConfirmation={false}
                  onConfirm={handleUpdateDatum}
                  title={`Set active to ${
                    newAttributeValue === true ? "true" : "false"
                  }?`}
                >
                  <Button
                    intent="danger"
                    text="Confirm Edit"
                    loading={loadingUserUpdate || loadingCountUpdate}
                  />
                </InputActionConfirmationDialogue>
              ) : (
                <Button
                  onClick={handleUpdateDatum}
                  intent="danger"
                  text="Confirm Edit"
                  disabled={
                    newAttributeValue === null || newAttributeValue === ""
                  }
                  loading={loadingUserUpdate || loadingCountUpdate}
                  key={`confirm__btn`}
                />
              ),
            ]}
          />
        </Dialog>
      </>
    );
  }
);

export default EditAttributeDialog;
