import React from "react";

import actions from "redux/auth/actions";
import { useAppDispatch } from "redux/hooks";

type ViewAsStatusBarProps = {
  viewAs: {
    isViewing: boolean;
    viewAsRole: string;
  };
  setViewAs: React.Dispatch<
    React.SetStateAction<{
      isViewing: boolean;
      viewAsRole: string;
    }>
  >;
};

const ViewAsStatusBar = ({ viewAs, setViewAs }: ViewAsStatusBarProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className="p-1" style={{ backgroundColor: "#bb86fc" }}>
      <p style={{ marginTop: 10, textAlign: "center" }}>
        You are currently logged in as {viewAs.viewAsRole}.{" "}
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            setViewAs({
              isViewing: false,
              viewAsRole: "",
            });
            dispatch(actions.GET_USER_CLAIMS());
          }}
        >
          Logout
        </span>
      </p>
    </div>
  );
};

export default ViewAsStatusBar;
