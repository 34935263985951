import { WithId } from "@stockifi/shared";
import {
  IChecklist,
  IChecklistGroup,
} from "components/app-tutorials/checklists";
import { IAppTutorial, TutorialMedia } from "components/app-tutorials/types";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "services/firebase";

export const tutorialsCollectionRef = db
  ? collection(db, "appTutorials")
  : null;

export const getTutorials = async () => {
  if (!tutorialsCollectionRef) return [];
  return getDocs(tutorialsCollectionRef)
    .then((data) => {
      const t = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as WithId<IAppTutorial>[];
      t.sort((a, b) => a.title.localeCompare(b.title));
      return t;
    })
    .catch((error) => {
      console.error("Error getting app tutorials documents: ", error);
      return [];
    });
};

export async function uploadMedia(
  tutorialId: string,
  file: File
): Promise<TutorialMedia> {
  return await new Promise(function (resolve, reject) {
    if (!storage) return reject({ data: null, error: "No storage connection" });
    const path = "appTutorials/" + tutorialId + "/" + file.name;
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      customMetadata: { deleted: "false" },
    });

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        return;
      },
      function error(err) {
        reject({ data: null, error: err });
      },
      function complete() {
        Promise.all([
          getDownloadURL(uploadTask.snapshot.ref),
          getMetadata(uploadTask.snapshot.ref),
        ]).then((data) => {
          resolve({
            url: data[0],
            type: data[1].contentType?.split("/")[0] ?? "",
            deleted: data[1].customMetadata?.deleted,
            name: data[1].name,
          });
        });
      }
    );
  });
}

export const getChecklists = async () => {
  try {
    if (!db) throw new Error("No database connection");
    return getDocs(collection(db, "appTutorialsChecklists"))
      .then((data) => {
        const checklists = data.docs.map(
          (doc) => new IChecklist(doc.id, doc.data())
        );
        return checklists;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    console.error("Error getting app tutorials checklists: ", error);
    return [];
  }
};

export const createChecklist = async (
  title: string,
  groups: IChecklistGroup[]
) => {
  try {
    if (!db) throw new Error("No database connection");

    const newChecklist = {
      title,
      groups,
      deleted: false,
      createdAt: new Date(),
    };
    return addDoc(collection(db, "appTutorialsChecklists"), newChecklist)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        throw new Error(err);
      });
  } catch (error) {
    console.error("Error creating app tutorials checklist: ", error);
  }
};

export const updateChecklist = async (id: string, data: any) => {
  try {
    if (!db) throw new Error("No database connection");
    return updateDoc(doc(db, "appTutorialsChecklists", id), data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        throw new Error(err);
      });
  } catch (error) {
    console.error("Error updating app tutorials checklist: ", error);
  }
};
