import { Button, Dialog, DialogBody } from "@blueprintjs/core";
import { useState } from "react";
import { getChecklists } from "services/app-tutorials";
import { IAppTutorial } from "../types";
import { Loader, WithId } from "@stockifi/shared";
import AddNewChecklist from "./add-new-checklist";
import { DocumentData } from "firebase/firestore";
import styles from "./index.module.scss";
import Checklist from "./checklist";

export interface IChecklistGroup {
  title: string;
  tutorials: string[];
}

export class IChecklist {
  id: string;
  groups: IChecklistGroup[];
  createdAt: Date;
  deleted: boolean;
  title: string;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.groups = data.groups ?? [];
    this.createdAt = data.createdAt.toDate();
    this.deleted = data.deleted ?? false;
    this.title = data.title || id;
  }
}

interface Props {
  tutorials: WithId<IAppTutorial>[];
}

const Checklists = ({ tutorials }: Props) => {
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [loading, setLoading] = useState(false);

  const openDialog = async () => {
    setOpenDialog(true);
    fetchChecklists();
  };

  const fetchChecklists = async () => {
    setLoading(true);
    await getChecklists().then((res) => {
      setChecklists(res);
      setLoading(false);
    });
  };

  return (
    <>
      <Button onClick={openDialog} loading={loading}>
        Checklists
      </Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        title="App Tutorials Checklists"
        style={{
          width: "90vw",
          height: "90vh",
        }}
      >
        <Loader loading={loading} className="h-100">
          <DialogBody className={styles.dialog__body}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 20,
              }}
            >
              <AddNewChecklist
                tutorials={tutorials}
                fetchChecklists={fetchChecklists}
              />
            </div>
            <div>
              {checklists.length > 0 ? (
                checklists.map((checklist, index) => (
                  <Checklist
                    key={checklist.createdAt.toString()}
                    checklist={checklist}
                    checklists={checklists}
                    index={index}
                    tutorials={tutorials}
                    fetchChecklists={fetchChecklists}
                  />
                ))
              ) : (
                <div>
                  There is no checklist found. Please create a new checklist.
                </div>
              )}
            </div>
          </DialogBody>
        </Loader>
      </Dialog>
    </>
  );
};

export default Checklists;
