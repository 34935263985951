import { Button, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { IChecklist } from "components/app-tutorials/checklists";
import { ComponentProps, useEffect, useState } from "react";
import { getChecklists } from "services/app-tutorials";

interface Props {
  onSelect: (checklist: IChecklist) => void;
  selectedValue: string;
  buttonProps?: ComponentProps<typeof Button>;
}

const AssignTutorialChecklist = ({
  onSelect,
  selectedValue,
  buttonProps,
}: Props) => {
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTutorialChecklists = async () => {
      setLoading(true);
      await getChecklists()
        .then((checklists) => {
          setChecklists(checklists);
        })
        .finally(() => setLoading(false));
    };
    getTutorialChecklists();
  }, []);

  return (
    <Select<IChecklist>
      items={checklists}
      filterable
      itemPredicate={(query, checklist) => {
        return (
          checklist.title?.toLowerCase().includes(query.toLowerCase()) ||
          checklist.id.toLowerCase().includes(query.toLowerCase())
        );
      }}
      itemRenderer={(checklist, { handleClick, modifiers }) => (
        <MenuItem
          active={modifiers.active}
          key={checklist.id}
          onClick={handleClick}
          text={checklist.title || checklist.id}
        />
      )}
      onItemSelect={onSelect}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
    >
      <Button
        text={
          checklists.find((c) => c.id === selectedValue)?.title ||
          selectedValue ||
          "Assign Tutorial Checklist"
        }
        rightIcon="double-caret-vertical"
        loading={loading}
        {...buttonProps}
      />
    </Select>
  );
};

export default AssignTutorialChecklist;
