import { DocumentData } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import {
  setOTPListener,
  setOTPMessageListener,
} from "services/listeners/scripts";
import {
  getUserCountAreas,
  getUserCounts,
  getVendorOtp,
  updateOtp,
} from "services/scripts";
import actions from "./actions";

export interface GET_USER_COUNTS_Payload {
  userId: string;
}

function* GET_USER_COUNTS(inp: ReturnType<typeof actions.GET_USER_COUNTS>) {
  const { userId } = inp.payload;

  yield put(
    actions.SET_STATE({
      loadingGetCounts: true,
    })
  );

  const { data }: ServiceReturn = yield call(getUserCounts, userId);

  if (data) {
    yield put(
      actions.SET_STATE({
        loadingGetCounts: false,
        userCounts: data,
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        loadingGetCounts: false,
      })
    );
  }
}

export interface GET_USER_COUNT_AREAS_payload {
  userId: string;
}

function* GET_USER_COUNT_AREAS(
  inp: ReturnType<typeof actions.GET_USER_COUNT_AREAS>
) {
  yield put(
    actions.SET_STATE({
      loadingGetCountAreas: true,
    })
  );

  const { userId } = inp.payload;
  const { data }: ServiceReturn = yield call(getUserCountAreas, userId);

  if (data) {
    yield put(
      actions.SET_STATE({
        userCountAreas: data,
        loadingGetCountAreas: false,
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        loadingGetCountAreas: false,
      })
    );
    yield put(alertActions.ERROR("Error getting user counts"));
  }
}

function* GET_USER_OTP() {
  yield put(
    actions.SET_STATE({
      loadingOtp: true,
    })
  );

  const { data }: ServiceReturn = yield call(getVendorOtp);

  if (data) {
    yield put(
      actions.SET_STATE({
        otp: data,
      })
    );
  } else {
    yield put(alertActions.ERROR("Error getting otp"));
  }

  yield put(
    actions.SET_STATE({
      loadingOtp: false,
    })
  );
}

export interface UPDATE_USER_OTP_payload {
  payloadData: DocumentData;
  clearOtp?: boolean;
}
function* UPDATE_USER_OTP(inp: ReturnType<typeof actions.UPDATE_USER_OTP>) {
  const { payloadData, clearOtp } = inp.payload;

  if (!clearOtp) yield put(actions.SET_STATE({ loadingOtp: true })); // Don't show loading if clearing otp for the first time
  const { data }: ServiceReturn = yield call(updateOtp, payloadData);

  if (data) {
    const message = clearOtp ? "OTPs cleared." : "OTPs updated.";
    yield put(alertActions.SUCCESS(message));
  } else {
    yield put(alertActions.ERROR("Error updating otp"));
  }

  yield put(actions.SET_STATE({ loadingOtp: false }));
}

function* SUBSCRIBE_TO_OTP_MESSAGES() {
  yield put(actions.SET_STATE({ loadingOtpMessages: true }));
  const channel: EventChannel<boolean> = yield call(setOTPMessageListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_OTP_MESSAGES);
    channel.close();
  });

  try {
    while (true) {
      const otpMessages: Record<string, string> = yield take(channel);
      yield put(
        actions.SET_STATE({
          otpMessages,
          loadingOtpMessages: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}

function* SUBSCRIBE_TO_OTP() {
  yield put(actions.SET_STATE({ loadingOtp: true }));
  const channel: EventChannel<boolean> = yield call(setOTPListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_OTP);
    channel.close();
  });

  try {
    while (true) {
      const otp: Record<string, string> = yield take(channel);
      yield put(
        actions.SET_STATE({
          otp,
          loadingOtp: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USER_COUNTS, GET_USER_COUNTS),
    takeLatest(actions.GET_USER_COUNT_AREAS, GET_USER_COUNT_AREAS),
    takeLatest(actions.GET_USER_OTP, GET_USER_OTP),
    takeLatest(actions.UPDATE_USER_OTP, UPDATE_USER_OTP),
    takeLatest(actions.SUBSCRIBE_TO_OTP_MESSAGES, SUBSCRIBE_TO_OTP_MESSAGES),
    takeLatest(actions.SUBSCRIBE_TO_OTP, SUBSCRIBE_TO_OTP),
  ]);
}
