/**
 * @category Integration Monitor
 * @module IntegrationMonitorSaga
 */

import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import {
  CalendarData,
  ImMatricData,
  IntegrationMonitorState,
  ScrapersData,
  ScrapersLog,
} from "redux/integration-monitor/types";
import { ServiceReturn } from "redux/types";
import {
  getImageDownloadUrl,
  getIntegrationsFlag,
  getPosFiles,
  getScraperLogs,
  getScrapersDataByDataId,
  getScrapersDataByUserId,
  getScrapersLogByLogId,
  updateLogNotification,
} from "services/integration-monitor";
import {
  setCalendarDataListener,
  setImMetricsListener,
  setScrapersLogListener,
  setScrapersLogListenerByUserId,
} from "services/listeners/integrationMonitor";
import { COMMON } from "utils/constants";
import actions from "./actions";

/**
 * Type of `GET_DATA_BY_DATAID` payload
 * @category Integration Monitor
 */
export interface GET_DATA_BY_DATAID_Payload {
  /** Scrapers data ID */
  dataId: string;
}

/**
 * Saga to get scrapers data by scrapers log ID.
 * After call service to get scrapers data using scrapers log ID, this function will set the result into redux state.
 * @generator
 * @param param Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.GET_DATA_BY_DATAID({ dataId: data.dataId })
 * );
 */
export function* GET_DATA_BY_DATAID({
  payload,
}: ReturnType<typeof actions.GET_DATA_BY_DATAID>) {
  const dataId = payload.dataId;
  yield put(
    actions.UPDATE_SCRAPERS_DATA_STATE({
      [dataId]: {
        createdAt: Timestamp.now(),
        data: [],
        logId: "",
        scraperName: "",
        userId: "",
        loadingScrapersData: true,
      },
    })
  );
  const data: ScrapersData = yield call(getScrapersDataByDataId, dataId);
  yield put(
    actions.UPDATE_SCRAPERS_DATA_STATE({
      [dataId]: { ...data, loadingScrapersData: false },
    })
  );
}

/**
 * Type of `GET_DATA_BY_USERID` payload
 * @category Integration Monitor
 */
export interface GET_DATA_BY_USERID_Payload {
  /** User ID */
  userId: string;
}

/**
 * Saga to get scrapers data by User ID.
 * After call service to get scrapers data using user ID, this function will set the result into redux state.
 * @generator
 * @param param Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.GET_DATA_BY_USERID({
 *     userId: selectedUserIm.id
 *   }),
 * );
 */
export function* GET_DATA_BY_USERID({
  payload,
}: ReturnType<typeof actions.GET_DATA_BY_USERID>) {
  const { userId } = payload;

  yield put(
    actions.SET_STATE({
      loadingScrapersLogsData: true,
    })
  );
  const data: DocumentData[] = yield call(getScrapersDataByUserId, userId);
  yield put(
    actions.SET_STATE({
      scraperDataToSummary: data,
      loadingScrapersLogsData: false,
    })
  );
}

/**
 * Type of `GET_IMAGES` payload
 * @category Integration Monitor
 */
export interface GET_IMAGES_Payload {
  /** Array of image urls, containing image url (path) and image name */
  imageUrls: { url: string; name: string }[];
  /** Scrapers log ID */
  logId: string;
}

/**
 * Saga to get images. Accept array of image urls
 * After call service to get images link, this function will set images link into redux state.
 * @generator
 * @param param Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.GET_IMAGES({
 *     imageUrls: files,
 *     logId
 *   })
 * );
 */
export function* GET_IMAGES({
  payload,
}: ReturnType<typeof actions.GET_IMAGES>) {
  const { imageUrls, logId } = payload;
  yield put(
    actions.UPDATE_FILES_DATA_STATE({
      [logId]: {
        images: [],
        loadingImages: true,
      },
    })
  );

  const images: { url: string; name: string }[] = yield call(
    getImageDownloadUrl,
    imageUrls
  );
  yield put(
    actions.UPDATE_FILES_DATA_STATE({
      [logId]: {
        images: images,
        loadingImages: false,
      },
    })
  );
}

/**
 * Type of `GET_IMAGE` payload
 * @category Integration Monitor
 */
export interface GET_IMAGE_Payload {
  /** Image url (path) */
  imageUrl: string;
  /** Scrapers log ID */
  logId: string;
}
/**
 * Saga to get single image.
 * After call service to get image link, this function will set image link into redux state.
 * @generator
 * @param param Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.GET_IMAGE({
 *     imageUrl: "https://firebasestorage.googleapis.com/v0/b/abcdefgh",
 *     logId: "123456789",
 *   })
 * );
 */
export function* GET_IMAGE({ payload }: ReturnType<typeof actions.GET_IMAGE>) {
  const { imageUrl, logId } = payload;
  yield put(
    actions.SET_STATE({
      openedImage: [],
      loadingImages: true,
    })
  );

  const images: IntegrationMonitorState["openedImage"] = yield call(
    getImageDownloadUrl,
    [{ url: imageUrl, name: "" }]
  );

  yield put(
    actions.SET_STATE({
      openedImage: [...images],
      loadingImages: false,
      imagesLogId: logId,
    })
  );
}

/**
 * Type of `GET_SCRAPERS` payload
 * @category Integration Monitor
 */
export interface GET_SCRAPERS_Payload {
  /** Selected user ID */
  userId: string;
  vendorName: string;
  direction?: "next" | "previous";
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  firstDoc?: QueryDocumentSnapshot<DocumentData>;
  /** Selected vendor type */
  type?: "POS" | "AP";
}

/**
 * Saga channel to listen scrapers log data from firestore.
 * After call service to create listener of scrapers log data, this saga will listen into data stream of scrapers logs data.
 * Listened data will stored in redux state.
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actionsIM from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actionsIM.SUBSCRIBE_TO_SCRAPERS_LOGS({
 *     userId, type, direction, dateFrom,
 *   });
 * )
 */
function* SUBSCRIBE_TO_SCRAPERS_LOGS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_SCRAPERS_LOGS>
) {
  const { userId, vendorName, lastDoc, firstDoc, direction } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingScrapersLogs: true,
      scrapersLogs: {
        data: [],
      },
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setScrapersLogListener,
    userId,
    vendorName,
    lastDoc,
    firstDoc,
    direction
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_SCRAPERS_LOGS);
    channel.close();
    yield put(
      actions.SET_STATE({
        scrapersLogs: { data: [] },
      })
    );
  });

  try {
    while (true) {
      const { data, lastDoc, firstDoc } = yield take(channel);
      yield put(
        actions.SET_STATE({
          scrapersLogs: { data, lastDoc, firstDoc },
          loadingScrapersLogs: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingScrapersLogs: false }));
    }
  }
}

/**
 * Type of `GET_CALENDAR_DATA` payload
 * @category Integration Monitor
 */
export interface GET_CALENDAR_DATA_Payload {
  /** Selected user ID */
  userId: string;
  vendorName: string;
  selectedDate?: Date;
}

/**
 * Saga channel to listen calendar data data from firestore.
 * After call service to create listener of calendar data, this saga will listen into data stream of calendar data.
 * Listened data will stored in redux state.
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actionsIM from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actionsIM.SUBSCRIBE_CALENDAR_DATA({
 *     userId, type, selectedDate,
 *   });
 * )
 */
function* SUBSCRIBE_CALENDAR_DATA(
  input: ReturnType<typeof actions.SUBSCRIBE_CALENDAR_DATA>
) {
  const { userId, vendorName, selectedDate } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingCalendarData: true,
      calendarData: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setCalendarDataListener,
    userId,
    vendorName,
    selectedDate
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_SCRAPERS_LOGS);
    channel.close();
    yield put(
      actions.SET_STATE({
        calendarData: [],
      })
    );
  });

  try {
    while (true) {
      const data: CalendarData[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          calendarData: data,
          loadingCalendarData: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingCalendarData: false }));
    }
  }
}

/**
 * Type of `GET_IM_METRICS` payload
 * @category Integration Monitor
 */
export interface GET_IM_METRICS_Payload {
  dateFrom: Date;
  dateTo: Date;
}

/**
 * Saga channel to listen integration monitor metrics data from firestore.
 * After call service to create listener of integration monitor metrics data, this saga will listen into data stream of integration monitor metrics data.
 * Listened data will stored in redux state.
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actionsIM from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actionsIM.SUBSCRIBE_TO_IM_METRICS({
 *      dateFrom, dateTo
 *   });
 * )
 */
function* SUBSCRIBE_TO_IM_METRICS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_IM_METRICS>
) {
  const { dateFrom, dateTo } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingImMetrics: true,
      imMetrics: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setImMetricsListener,
    dateFrom,
    dateTo
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_IM_METRICS);
    channel.close();
    yield put(
      actions.SET_STATE({
        imMetrics: [],
      })
    );
  });

  try {
    while (true) {
      const data: ImMatricData[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          imMetrics: data,
          loadingImMetrics: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingImMetrics: false }));
    }
  }
}

/**
 * Type of `UPDATE_LOG_NOTIFICATION` payload
 * @category Integration Monitor
 */
export interface UPDATE_LOG_NOTIFICATION_Payload {
  /** Scrapers log firestore docs ref */
  logRef: DocumentReference;
  /** Boolean indicates whether system should set notification show or not */
  notificationShow: boolean;
  /** Scrapers log ID */
  id: string;
}

/**
 * Saga to update scrapers log notification
 * When calling the action, this set the loading state into true. After call the service, it will set the loading state into false
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.UPDATE_LOG_NOTIFICATION({
 *     logRef: data.ref,
 *     notificationShow: false,
 *     id: dataId,
 *   })
 * );
 */
export function* UPDATE_LOG_NOTIFICATION(
  input: ReturnType<typeof actions.UPDATE_LOG_NOTIFICATION>
) {
  const { logRef, notificationShow, id } = input.payload;
  yield put(actions.ADD_LOADING_STATE(id));
  yield call(updateLogNotification, logRef, notificationShow);
  yield put(actions.REMOVE_LOADING_STATE(id));
}

/**
 * Type of `GET_POS_FILES_BY_USERID` payload
 * @category Integration Monitor
 */
export interface GET_POS_FILES_BY_USERID_Payload {
  /** Selected user ID */
  userId: string;
  /** Selected date */
  selectedDate: Date;
}

/**
 * Saga to get POS files by user ID
 * After call service to get POS files by user ID, this saga will set POS files data into redux.
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actions.GET_POS_FILES_BY_USERID({
 *     userId: selectedUserIm.id,
 *     selectedDate
 *   })
 * );
 */
export function* GET_POS_FILES_BY_USERID(
  input: ReturnType<typeof actions.GET_POS_FILES_BY_USERID>
) {
  const { userId, selectedDate } = input.payload;
  const startTime = moment(selectedDate).startOf("month").toDate();
  const endTime = moment(selectedDate).endOf("day").toDate();

  const endOfTheMonth = moment(selectedDate).endOf("month").toDate();
  const isLastDayOfMonth = moment(endTime).isSame(endOfTheMonth, "day");

  yield put(actions.SET_STATE({ loadingAllSalesData: true }));

  const result: ServiceReturn = yield call(
    getPosFiles,
    userId,
    startTime,
    endTime,
    isLastDayOfMonth
  );
  if (result.data) {
    yield put(
      actions.SET_STATE({
        posFiles: result.data,
        loadingAllSalesData: false,
      })
    );
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

/**
 * Saga to get all field from each vendor
 * After call service to get Field data, this saga will set integrations Fields data into redux.
 * @generator
 * @yields {void}
 * @example
 * // Import the action first
 * import actions from "redux/integration-monitor/actions";
 *
 * dispatch(actions.GET_INTEGRATIONS_FLAGS());
 */
export function* GET_INTEGRATIONS_FLAGS() {
  const result: ServiceReturn = yield call(getIntegrationsFlag);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        integrationsFields: result.data,
      })
    );
  } else if (result.error) {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface GET_SCRAPER_LOGS_Payload {
  userId: string;
  type: "POS" | "AP";
}

export function* GET_SCRAPER_LOGS(
  payload: ReturnType<typeof actions.GET_SCRAPER_LOGS>
) {
  yield put(
    actions.SET_STATE({
      loading: true,
    })
  );
  const result: ServiceReturn = yield call(
    getScraperLogs,
    payload.payload.userId,
    payload.payload.type
  );
  if (result.data) {
    yield put(
      actions.SET_STATE({
        scraperLogIds: result.data,
        loading: false,
      })
    );
  } else if (result.error) {
    yield put(
      actions.SET_STATE({
        loading: false,
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

function* SUBSCRIBE_TO_SCRAPERS_LOGS_BY_USERID(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_SCRAPERS_LOGS_BY_USERID>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loading: true,
      scraperLogIds: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setScrapersLogListenerByUserId,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_SCRAPERS_LOGS_BY_USERID);
    channel.close();
    yield put(
      actions.SET_STATE({
        scraperLogIds: [],
      })
    );
  });

  try {
    while (true) {
      const { scraperLogIds } = yield take(channel);
      yield put(
        actions.SET_STATE({
          scraperLogIds: scraperLogIds,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

export interface GET_SCRAPER_LOG_BY_ID_Payload {
  logId: string;
}

export function* GET_SCRAPER_LOG_BY_ID(
  payload: ReturnType<typeof actions.GET_SCRAPER_LOG_BY_ID>
) {
  yield put(
    actions.SET_STATE({
      loadingScrapersLogs: true,
    })
  );
  const data: ScrapersLog = yield call(
    getScrapersLogByLogId,
    payload.payload.logId
  );

  yield put(
    actions.SET_STATE({
      scrapersLogs: { data: [data], lastDoc: undefined, firstDoc: undefined },
      loadingScrapersLogs: false,
    })
  );
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_DATA_BY_DATAID, GET_DATA_BY_DATAID),
    takeLatest(actions.GET_IMAGES, GET_IMAGES),
    takeLatest(actions.GET_IMAGE, GET_IMAGE),
    takeLatest(actions.SUBSCRIBE_TO_SCRAPERS_LOGS, SUBSCRIBE_TO_SCRAPERS_LOGS),
    takeEvery(actions.UPDATE_LOG_NOTIFICATION, UPDATE_LOG_NOTIFICATION),
    takeLatest(actions.GET_DATA_BY_USERID, GET_DATA_BY_USERID),
    takeLatest(actions.GET_POS_FILES_BY_USERID, GET_POS_FILES_BY_USERID),
    takeLatest(actions.GET_INTEGRATIONS_FLAGS, GET_INTEGRATIONS_FLAGS),
    takeLatest(actions.GET_SCRAPER_LOGS, GET_SCRAPER_LOGS),
    takeLatest(actions.GET_SCRAPER_LOG_BY_ID, GET_SCRAPER_LOG_BY_ID),
    takeLatest(
      actions.SUBSCRIBE_TO_SCRAPERS_LOGS_BY_USERID,
      SUBSCRIBE_TO_SCRAPERS_LOGS_BY_USERID
    ),
    takeLatest(actions.SUBSCRIBE_TO_IM_METRICS, SUBSCRIBE_TO_IM_METRICS),
    takeLatest(actions.SUBSCRIBE_CALENDAR_DATA, SUBSCRIBE_CALENDAR_DATA),
  ]);
}
