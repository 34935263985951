import { WithId } from "@stockifi/shared";
import { IChecklist } from "..";
import { IAppTutorial } from "components/app-tutorials/types";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import { useState } from "react";
import { updateChecklist } from "services/app-tutorials";
import { useAppDispatch } from "redux/hooks";
import actions from "redux/alert/actions";
import { Button } from "@blueprintjs/core";
import EditChecklist from "../edit-checklist";

interface Props {
  checklist: IChecklist;
  checklists: IChecklist[];
  index: number;
  tutorials: WithId<IAppTutorial>[];
  fetchChecklists: () => void;
}

const Checklist = ({
  checklist,
  checklists,
  index,
  tutorials,
  fetchChecklists,
}: Props) => {
  const dispatch = useAppDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleDeleteChecklist = async (id: string, isDeleted: boolean) => {
    setUpdateLoading(true);
    await updateChecklist(id, { deleted: !isDeleted }).then(() => {
      fetchChecklists();
      setUpdateLoading(false);
      dispatch(
        actions.SUCCESS(
          `Checklist ${isDeleted ? "undeleted" : "deleted"} successfully`
        )
      );
    });
  };
  return (
    <div key={checklist.createdAt.toString()}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3>Title: {checklist.title}</h3>
          <h3>Created At: {checklist.createdAt.toString()}</h3>
          {checklist.groups.map((group) => (
            <div key={group.title}>
              <h4>{group.title}</h4>
              <ul>
                {group.tutorials.map((tutorialId) => {
                  const t = tutorials.find((t) => t.id === tutorialId);
                  return (
                    <li key={tutorialId}>
                      {t?.title || tutorialId}
                      {t?.deleted && (
                        <span style={{ color: "red", paddingLeft: 10 }}>
                          Deleted
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
          }}
        >
          <EditChecklist
            tutorials={tutorials}
            fetchChecklists={fetchChecklists}
            checklist={checklist}
          />
          <ActionConfirmationDialogue
            text="Are you sure you want to delete this checklist?"
            title=""
            onConfirm={() =>
              handleDeleteChecklist(checklist.id, checklist.deleted)
            }
          >
            <Button intent="danger" loading={updateLoading}>
              {checklist.deleted ? "Undelete" : "Delete"} Checklist
            </Button>
          </ActionConfirmationDialogue>
        </div>
      </div>
      {index !== checklists.length - 1 && <hr style={{ margin: "30px 0" }} />}
    </div>
  );
};

export default Checklist;
