import { withSentryReactRouterV6Routing } from "@sentry/react";
import { ModuleLoader } from "@stockifi/shared";
import { User } from "firebase/auth";
import { JSX, Suspense, lazy, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as _Routes,
} from "react-router-dom";

import { auth } from "services/firebase";

import Layout from "layouts";
import "style/common.css";

import Suppliers from "pages/suppliers";
import actions from "redux/auth/actions";
import { AuthClaims } from "redux/auth/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const Routes = withSentryReactRouterV6Routing(_Routes);

// turn on lazy loading
const Home = lazy(() => import("pages/home"));
const Login = lazy(() => import("pages/auth/login"));
const DataLabeling = lazy(() => import("pages/data-labeling"));
const APTransactions = lazy(() => import("pages/ap-transactions"));
const Docs = lazy(() => import("pages/docs"));
const Prompts = lazy(() => import("pages/prompts"));
const UserStatus = lazy(() => import("pages/user-status"));
const UserStatusAllUsers = lazy(() => import("pages/user-status-all-users"));
const UserStatusOnboardingUsers = lazy(
  () => import("pages/user-status-onboarding-users")
);
const IntegrationMonitor = lazy(() => import("pages/integration-monitor"));
const IntegrationUser = lazy(
  () => import("components/integration-monitor/integration-user")
);
const UserList = lazy(() => import("pages/users"));
const AppTutorials = lazy(() => import("pages/app-tutorials"));
const Lists = lazy(() => import("pages/lists"));
const Silhouettes = lazy(() => import("pages/silhouettes"));
const LoginToken = lazy(() => import("pages/login-token"));
const Permissions = lazy(() => import("pages/permissions"));
const Counts = lazy(() => import("pages/counts"));
const TablePDF2XLSX = lazy(() => import("pages/table-pdf2xlsx"));
const Scripts = lazy(() => import("pages/scripts"));
const POSItemTasks = lazy(() => import("pages/pos-item-tasks"));
const TasksList = lazy(() => import("components/pos-item-tasks/tasks-list"));
const InvoiceList = lazy(() => import("components/invoices/invoice-list"));
const InvoiceCheckingList = lazy(
  () => import("components/invoices/invoice-checking-list")
);
const InvoiceVotesResults = lazy(
  () => import("components/data-voting/invoice-votes-results")
);
const PredefinedFilterManager = lazy(
  () => import("pages/predefined-filter-manager")
);

const Invoices = lazy(() => import("pages/invoices"));
const Sales = lazy(() => import("pages/sales"));

function Router() {
  const dispatch = useAppDispatch();
  // TODO: Refactor react-firebase-hooks -- Might not be compatible with newer versions of Firebase

  const [user, loading] = useAuthState(auth as any);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const permissions = useAppSelector((state) => state.appConfig.permissions);

  useEffect(() => {
    if (
      user &&
      authClaims &&
      !authClaims.admin &&
      !authClaims.dev &&
      !authClaims.supervisor &&
      !authClaims.invoices
    ) {
      dispatch(actions.LOGOUT());
      dispatch(
        actions.SET_STATE({
          error: { code: "Permission denied" },
        })
      );
    }
  }, [dispatch, user, authClaims]);

  useEffect(() => {
    dispatch(actions.GET_USER_CLAIMS());
  }, [user, dispatch]);

  if (loading) return null;

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    user: user,
  };

  const defaultAuthorizedRouteProps: Omit<
    AuthorizedRouteProps,
    "outlet" | "allowedAccess"
  > = {
    user: user,
    authClaims: authClaims,
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<Layout />}
            />
          }
        >
          <Route
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/data-labeling"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <DataLabeling
                      features={[
                        "invoices-resolving",
                        "invoices-checking",
                        "pos-item-tasks",
                        "invoice-votes-results",
                      ]}
                    />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "dataLabeling")?.roles
                }
              />
            }
          >
            <Route
              index
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <Invoices initialTab="resolving" />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "dataLabeling")?.roles
                  }
                />
              }
            />
            <Route
              path="invoices-resolving"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <Invoices initialTab="resolving" />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "dataLabeling")?.roles
                  }
                />
              }
            >
              <Route
                path=":userIdParam"
                element={
                  <AuthorizedRoute
                    {...defaultAuthorizedRouteProps}
                    outlet={
                      <Suspense fallback={<Loading />}>
                        <InvoiceList />
                      </Suspense>
                    }
                    allowedAccess={
                      permissions.find((p) => p.code === "dataLabeling")?.roles
                    }
                  />
                }
              />
            </Route>
            <Route
              path="invoices-checking"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <Invoices initialTab="checking" />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "dataLabeling")?.roles
                  }
                />
              }
            >
              <Route
                path=":userIdParam?"
                element={
                  <AuthorizedRoute
                    {...defaultAuthorizedRouteProps}
                    outlet={
                      <Suspense fallback={<Loading />}>
                        <InvoiceCheckingList />
                      </Suspense>
                    }
                    allowedAccess={
                      permissions.find((p) => p.code === "dataLabeling")?.roles
                    }
                  />
                }
              />
            </Route>
            <Route
              path="pos-item-tasks"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <POSItemTasks />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "dataLabeling")?.roles
                  }
                />
              }
            >
              <Route
                path=":userIdParam"
                element={
                  <AuthorizedRoute
                    {...defaultAuthorizedRouteProps}
                    outlet={
                      <Suspense fallback={<Loading />}>
                        <TasksList />
                      </Suspense>
                    }
                    allowedAccess={
                      permissions.find((p) => p.code === "dataLabeling")?.roles
                    }
                  />
                }
              />
            </Route>
            <Route
              path="invoice-votes-results"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <InvoiceVotesResults />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "dataLabeling")?.roles
                  }
                />
              }
            />
          </Route>
          <Route
            path="/ap-transactions"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <APTransactions />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "apTransactions")?.roles
                }
              />
            }
          >
            <Route
              path=":id"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <APTransactions />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "apTransactions")?.roles
                  }
                />
              }
            />
          </Route>

          <Route
            path="/docs"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Docs />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "docs")?.roles
                }
              />
            }
          />

          <Route
            path="/prompts"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Prompts />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "prompts")?.roles
                }
              />
            }
          />

          <Route
            path="/table-pdf2xlsx"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <TablePDF2XLSX />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/user-status"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <UserStatus />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "userStatus")?.roles
                }
              />
            }
          >
            <Route
              index
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <UserStatusAllUsers />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "userStatus")?.roles
                  }
                />
              }
            />
            <Route
              path="all-users"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <UserStatusAllUsers />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "userStatus")?.roles
                  }
                />
              }
            />
            <Route
              path="onboarding-users"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <UserStatusOnboardingUsers />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "userStatus")?.roles
                  }
                />
              }
            />
          </Route>
          <Route
            path="/users"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <UserList />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "users")?.roles
                }
              />
            }
          />
          <Route
            path="/suppliers"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Suppliers />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "suppliers")?.roles
                }
              />
            }
          />
          <Route
            path="/lists"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Lists />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "lists")?.roles
                }
              />
            }
          >
            <Route
              path=":userIdParam"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <Lists />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "lists")?.roles
                  }
                />
              }
            >
              <Route
                path=":itemIdParam"
                element={
                  <AuthorizedRoute
                    {...defaultAuthorizedRouteProps}
                    outlet={
                      <Suspense fallback={<Loading />}>
                        <Lists />
                      </Suspense>
                    }
                    allowedAccess={
                      permissions.find((p) => p.code === "lists")?.roles
                    }
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="/silhouettes"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Silhouettes />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "silhouettes")?.roles
                }
              />
            }
          />
          <Route
            path="/counts"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Counts />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "counts")?.roles
                }
              />
            }
          />

          <Route
            path="/sales"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Sales />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "sales")?.roles
                }
              />
            }
          >
            <Route
              path=":userIdParam"
              element={
                <AuthorizedRoute
                  {...defaultAuthorizedRouteProps}
                  outlet={
                    <Suspense fallback={<Loading />}>
                      <Sales />
                    </Suspense>
                  }
                  allowedAccess={
                    permissions.find((p) => p.code === "sales")?.roles
                  }
                />
              }
            ></Route>
          </Route>

          <Route
            path="/integration-monitor"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <IntegrationMonitor />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "integrationMonitor")
                    ?.roles
                }
              />
            }
          >
            <Route
              path=":id"
              element={
                <Suspense fallback={<Loading />}>
                  <IntegrationUser />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/app-tutorials"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <AppTutorials />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "appTutorials")?.roles
                }
              />
            }
          />
          <Route
            path="/predefined-filter-manager"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <PredefinedFilterManager />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/permissions"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Permissions />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "permissions")?.roles
                }
              />
            }
          />
          <Route
            path="/scripts"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <Scripts />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "scripts")?.roles
                }
              />
            }
          />
          <Route
            path="login-token"
            element={
              <AuthorizedRoute
                {...defaultAuthorizedRouteProps}
                outlet={
                  <Suspense fallback={<Loading />}>
                    <LoginToken />
                  </Suspense>
                }
                allowedAccess={
                  permissions.find((p) => p.code === "integrationMonitor")
                    ?.roles
                }
              />
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <LoggedinRoute
              {...defaultProtectedRouteProps}
              outlet={
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              }
            />
          }
        />

        <Route
          path="*"
          element={
            <main className="main-padding">
              <p>There is nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export type ProtectedRouteProps = {
  user?: User | null;
  outlet: JSX.Element;
};

export type AuthorizedRouteProps = {
  user?: User | null;
  outlet: JSX.Element;
  authClaims?: AuthClaims;
  allowedAccess?: string[];
};

function ProtectedRoute({ user, outlet }: ProtectedRouteProps) {
  if (user) {
    return outlet;
  } else {
    return <Navigate to="/login" />;
  }
}

function LoggedinRoute({ user, outlet }: ProtectedRouteProps) {
  if (user) {
    return <Navigate to="/" />;
  } else {
    return outlet;
  }
}

function AuthorizedRoute({
  user,
  outlet,
  authClaims,
  allowedAccess,
}: AuthorizedRouteProps) {
  if (user && authClaims && allowedAccess) {
    let isAllowed = false;
    allowedAccess.forEach(function (value) {
      if (authClaims[value as keyof AuthClaims]) {
        isAllowed = true;
        return;
      }
    });

    if (isAllowed) {
      return outlet;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return outlet;
  }
}

export default Router;

export function Loading() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <ModuleLoader loading={loading}>
      <></>
    </ModuleLoader>
  );
}
