import { Button, H1, NonIdealState } from "@blueprintjs/core";
import { ModuleLoader } from "@stockifi/shared";
import { Attribute } from "components/edit-pinned-attributes";
import AddSupplier from "components/suppliers/add-supplier";
import EditSupplierBtn from "components/suppliers/edit-supplier-btn";
import Table from "components/table";
import Fuse from "fuse.js";
import { useSearch } from "layouts";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/suppliers/actions";
import { Supplier } from "redux/suppliers/types";

function Suppliers() {
  const { searchProps, setSearchProps } = useSearch();
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const loadingSuppliers = useAppSelector((state) => state.suppliers.loading);

  const [page, setPage] = useState(1);
  const basicColumns = [
    { id: 1, name: "name" },
    { id: 2, name: "deleted" },
    { id: 3, name: "address" },
    { id: 4, name: "zip" },
    { id: 5, name: "companyNumber" },
  ];
  const [pinnedCols, setPinnedCols] = useState<Attribute[]>(basicColumns);
  const [isSelectedAll, setSelectedAll] = useState<Record<number, boolean>>({});
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    Record<number, string[]>
  >({});
  const [sortedData, setSortedData] = useState<Supplier[]>(suppliers);
  const [filterPerPage, setFilterPerPage] = useState<number>(10);
  const [filterFrom, setFilterFrom] = useState<number>(0);
  const [filteredResults, setFilteredResults] = useState<Supplier[]>(suppliers);
  const [attrMap, setAttrMap] = useState<Map<string, string>>(new Map());
  const [isOpenAddSupplier, setOpenAddSupplier] = useState(false);

  const getAllAttributes = () => {
    const allAttributes = new Map();
    for (const supplier of suppliers) {
      const userKeys = Object.keys(supplier);
      for (const property of userKeys) {
        const propValue = supplier[property as keyof typeof supplier];
        if (
          propValue !== null &&
          propValue !== undefined &&
          (typeof propValue === "string" ||
            typeof propValue === "boolean" ||
            (typeof propValue === "number" && !isNaN(propValue)))
        ) {
          if (!allAttributes.has(property)) {
            allAttributes.set(property, typeof propValue);
          }
        }
      }
    }
    setAttrMap(allAttributes);
  };

  useEffect(() => {
    setSearchProps({
      ...searchProps,
      placeholder: "Filter suppliers...",
      readOnly: false,
    });
  }, []);

  useEffect(() => {
    getAllAttributes();
  }, [suppliers]);

  useEffect(() => {
    dispatch(actions.SUBSCRIBE_TO_SUPPLIERS({ includeDeleted: true }));

    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_SUPPLIERS());
    };
  }, [dispatch]);

  const fuse = new Fuse(suppliers, {
    keys: ["name", "id", "companyNumber", "address", "zip", "country"],
    threshold: 0.4,
    includeScore: true,
  });

  const filterUsers = useCallback(() => {
    if (searchProps.value === "") {
      return suppliers;
    } else {
      return fuse.search(searchProps.value).map((i) => i.item);
    }
  }, [suppliers, fuse, searchProps.value]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    const results = [...filterUsers()];
    if (searchProps.value === "" && selectedSuppliers[page]?.length === 0) {
      setFilteredResults(results);
    } else {
      const selected = suppliers.filter((supplier) => {
        return selectedSuppliers[page]?.some((x) => x === supplier.id);
      });
      const newUsers = results.filter((supplier) => {
        return !selectedSuppliers[page]?.some((x) => x === supplier.id);
      });
      newUsers.splice(filterFrom, 0, ...selected);
      setFilteredResults(newUsers);
    }
  }, [searchProps, selectedSuppliers, suppliers]);

  const getActions = (data: Supplier) => (
    <div>
      <EditSupplierBtn
        data={data}
        pinnedProps={pinnedCols}
        setPinnedProps={setPinnedCols}
        attrMap={attrMap}
      />
    </div>
  );

  const handleSelectAll = (e: FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    setSelectedAll((prev) => ({ ...prev, [page]: isChecked }));
    if (isChecked) {
      setSelectedSuppliers((prev) => ({
        ...prev,
        [page]: sortedData
          .slice(filterFrom, filterFrom + filterPerPage)
          .map((li) => li.id),
      }));
    } else {
      setSelectedSuppliers([]);
    }
  };

  const handleSelectSupplier = (e: FormEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;
    const updatedCheckArray = [...(selectedSuppliers[page] ?? []), id];
    setSelectedSuppliers((prev) => ({ ...prev, [page]: updatedCheckArray }));
    if (!checked) {
      setSelectedSuppliers((prev) => ({
        ...prev,
        [page]: prev[page]?.filter((item) => item !== id),
      }));
      setSelectedAll((prev) => ({ ...prev, [page]: false }));
    } else if (checked && updatedCheckArray.length === filteredResults.length) {
      setSelectedAll((prev) => ({ ...prev, [page]: true }));
    }
  };

  const getFieldValue = (
    field: string,
    value: string | number | { from: string; to: string }[]
  ) => {
    switch (field) {
      default:
        if (value === null) return "";
        if (value === undefined) return "";
        if (typeof value === "boolean") {
          if (value) return "Yes";
          else return "No";
        }
        return value.toString();
    }
  };

  return (
    <ModuleLoader loading={loadingSuppliers}>
      <div
        style={{
          marginBottom: ".5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <H1>Suppliers</H1>
        <Button onClick={() => setOpenAddSupplier(true)}>Add Supplier</Button>
        <AddSupplier isOpen={isOpenAddSupplier} setOpen={setOpenAddSupplier} />
      </div>
      <div>
        {filteredResults.length > 0 ? (
          <Table
            module="suppliers"
            page={page}
            setPage={setPage}
            data={filteredResults}
            firstColumn="name"
            pinnedCols={pinnedCols}
            setPinnedCols={setPinnedCols}
            getActions={getActions}
            isSelectedAll={isSelectedAll}
            selectedData={selectedSuppliers[page] ?? []}
            handleSelectAll={handleSelectAll}
            handleSelectRow={handleSelectSupplier}
            getFieldValue={getFieldValue}
            attrMap={attrMap}
            setSortedData={setSortedData}
            sortedData={sortedData}
            filterPerPage={filterPerPage}
            setFilterPerPage={setFilterPerPage}
            filterFrom={filterFrom}
            setFilterFrom={setFilterFrom}
          />
        ) : (
          <NonIdealState
            icon="user"
            title="No supplier found"
            description="Your search didn't match any supplier. Try searching for something else."
            layout="vertical"
          />
        )}
      </div>
    </ModuleLoader>
  );
}

export default Suppliers;
