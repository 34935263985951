import { Timestamp } from "firebase/firestore";

export type PromptsState = {
  loading: boolean;
  geminiPrompts: GeminiPrompt[];
  loadingAdd: boolean;
  loadingUpdate: boolean;
  activeSupplierPrompts: GeminiPrompt[];
};

export class GeminiPrompt {
  prompt: string;
  createdAt: Timestamp;
  createdBy: string;
  active: boolean;
  category: "invoice" | "transaction";
  id: string;
  updatedAt: Timestamp;
  supplierId: string;
  deleted: boolean;

  constructor(id: string, data: Partial<GeminiPrompt> = {}) {
    this.id = id;
    this.prompt = data.prompt ?? "";
    this.createdAt = data.createdAt ?? Timestamp.now();
    this.createdBy = data.createdBy ?? "";
    this.active = data.active ?? false;
    this.category = data.category ?? "invoice";
    this.updatedAt = data.updatedAt ?? Timestamp.now();
    this.supplierId = data.supplierId ?? "";
    this.deleted = data.deleted ?? false;
  }
}
