import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { Supplier } from "redux/suppliers/types";
import { db } from "services/firebase";

export function setSuppliersListener(
  includeDeleted: boolean = false
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints: QueryConstraint[] = [orderBy("name")];
    if (!includeDeleted) {
      constraints.unshift(where("deleted", "==", false));
    }

    const collRef = query(collection(db, "suppliers"), ...constraints);
    const unsub = onSnapshot(
      collRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: DocumentData[] = [];

        for (const doc of snapshot.docs) {
          if (doc.data().name) data.push(new Supplier(doc.id, doc.data()));
        }

        emitter(data);
      }
    );

    return () => unsub();
  });
}
