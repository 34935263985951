import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  collection,
  collectionGroup,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel, eventChannel } from "redux-saga";
import {
  APTransaction,
  ClassificationCountData,
} from "redux/ap-transactions/types";
import { db } from "services/firebase";

export function setApMetricsListener(
  dateFrom: Date,
  dateTo: Date,
  isJustForToday: boolean = false
): EventChannel<ClassificationCountData[]> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const metricsRef = query(
    collection(db, "metricsApTransaction"),
    where("createdAt", ">=", moment(dateFrom).startOf("day").toDate()),
    where("createdAt", "<=", moment(dateTo).endOf("day").toDate()),
    ...(isJustForToday ? [limit(1)] : [])
  );

  return eventChannel((emitter) => {
    const unsub = onSnapshot(
      metricsRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              }) as ClassificationCountData
          );
          emitter(data);
        }
      }
    );
    return () => unsub();
  });
}

export function tableViewTransactionsListener() {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 61);

    const constraints: QueryConstraint[] = [
      where("createdAt", ">=", newDate),
      where("deleted", "==", false),
      where("resolved", "==", false),
      orderBy("createdAt", "desc"),
    ];

    const userTransactionsQuery = query(
      collectionGroup(db, `invoiceTransactions`),
      ...constraints
    );
    const unsub = onSnapshot(
      userTransactionsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const invoiceTransactions: APTransaction[] = [];

        for (const doc of snapshot.docs) {
          const current = new APTransaction(doc.id, doc.ref, doc.data());
          const existingTransaction = invoiceTransactions.find(
            (x) => x.invoiceNumber && x.invoiceNumber === current.invoiceNumber
          );

          if (existingTransaction) {
            const combinedFiles = new Set<string>([
              ...(existingTransaction.files || []),
              ...(existingTransaction.path ? [existingTransaction.path] : []),
              ...(current.files || []),
              ...(current.path ? [current.path] : []),
            ]);

            existingTransaction.files = Array.from(combinedFiles);
          } else invoiceTransactions.push(current);
        }

        emitter(invoiceTransactions);
      }
    );

    return () => unsub();
  });
}
