import { createActions } from "redux/create-actions";
import {
  ADD_INVOICE_IMAGE_Payload,
  CREATE_INVOICE_Payload,
  GET_AP_METRICS_Payload,
  GET_IMAGES_Payload,
  GET_IMAGE_Payload,
  MARK_TRANSACTION_AS_SENSITIVE_Payload,
  SUBSCRIBE_TO_RESOLVED_TRANSACTIONS_Payload,
  SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS_Payload,
  UPLOAD_AND_CREATE_TRANSACTIONS_Payload,
} from "./sagas";
import {
  APTransactionsState,
  TransactionImage,
  UserStatusTransactions,
} from "./types";

const actions = createActions("ap-transactions", {
  SET_STATE: {} as Partial<APTransactionsState>,
  GET_IMAGE: {} as GET_IMAGE_Payload,
  GET_IMAGES: {} as GET_IMAGES_Payload,
  ADD_IMAGE: {} as TransactionImage,
  CREATE_INVOICE: {} as CREATE_INVOICE_Payload,
  ADD_INVOICE_IMAGE: {} as ADD_INVOICE_IMAGE_Payload,
  MARK_TRANSACTION_AS_SENSITIVE: {} as MARK_TRANSACTION_AS_SENSITIVE_Payload,
  SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS:
    {} as SUBSCRIBE_TO_UNRESOLVED_TRANSACTIONS_Payload,
  UNSUBSCRIBE_FROM_UNRESOLVED_TRANSACTIONS: undefined,
  SUBSCRIBE_TO_RESOLVED_TRANSACTIONS:
    {} as SUBSCRIBE_TO_RESOLVED_TRANSACTIONS_Payload,
  UNSUBSCRIBE_FROM_RESOLVED_TRANSACTIONS: undefined,
  SET_USER_STATUS_TRANSACTIONS: {} as Partial<UserStatusTransactions>,
  SUBSCRIBE_TO_AP_METRICS: {} as GET_AP_METRICS_Payload,
  UNSUBSCRIBE_FROM_AP_METRICS: undefined,
  UPLOAD_AND_CREATE_TRANSACTIONS: {} as UPLOAD_AND_CREATE_TRANSACTIONS_Payload,
  SUBSCRIBE_TO_TODAY_AP_METRICS: undefined,
  UNSUBSCRIBE_FROM_TODAY_AP_METRICS: undefined,
  SUBSCRIBE_TO_TABLE_VIEW_TRANSACTIONS: undefined,
  UNSUBSCRIBE_FROM_TABLE_VIEW_TRANSACTIONS: undefined,
});

export default actions;
