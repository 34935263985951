import { MenuItem } from "@blueprintjs/core";
import { isValidDateString } from "components/invoices/invoice/helpers";
import { isAllowedToSeeAllInvoice } from "components/invoices/permission.helpers";
import { Timestamp } from "firebase/firestore";
import React from "react";
import * as XLSX from "xlsx";
import store from "../redux/store";
import { User } from "../redux/users/types";

export function validateNumericOnly(
  event: React.KeyboardEvent<HTMLInputElement>,
  negative = false
) {
  //used key since keycode is already deprecated
  if (
    isNaN(Number(event.key)) &&
    event.key !== "Backspace" &&
    event.key !== "Delete" &&
    event.key !== "ArrowLeft" &&
    event.key !== "ArrowRight" &&
    (negative ? event.key !== "-" : true) &&
    event.key !== "."
  ) {
    event.preventDefault();
  }
}

export function validateNumeric(
  event: React.KeyboardEvent<HTMLInputElement>,
  // biome-ignore lint/correctness/noUnusedVariables: May need this
  negative = false
) {
  if (isNaN(Number(event.key))) {
    event.preventDefault();
  }
}

export function formatNumber(x: string | number, digits?: number | null) {
  // TODO: add support for locale
  const locale = "en-US";
  const fractionDigits = digits || 2;

  return $num(x).toLocaleString(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

export function formatOcrNumber(x: string, digits: number | null) {
  const fractionDigits = digits === null ? 2 : digits;

  // remove spaces in the number
  let number = x.toString().replace(/\s/g, "").replace(/,/g, ".");

  const dotIndex = number.lastIndexOf(".");
  if (dotIndex !== -1) {
    const integral = number.slice(0, dotIndex).replace(/\./g, "");
    const fractional = number.slice(dotIndex + 1, number.length);
    number = integral + "." + fractional;
  }

  if (digits !== null || number.indexOf(".") > -1) {
    return parseFloat(number).toFixed(fractionDigits);
  }
  return number;
}

function $num(x: string | number) {
  return typeof x !== "number" ? parseFloat(x) : x;
}

export const editArrayElementPosition = <TData extends object>(
  arr: TData[],
  oldIndex: number,
  newIndex: number
) => {
  const element = arr[oldIndex];
  arr.splice(oldIndex, 1);
  arr.splice(newIndex, 0, element);
};

export function getDurationString(seconds: number) {
  const hours = ("0" + Math.floor((seconds / 3600) % 60)).slice(-2);
  const minutes = ("0" + Math.floor((seconds / 60) % 60)).slice(-2);
  const secs = ("0" + Math.floor(seconds % 60)).slice(-2);
  return `${hours}:${minutes}:${secs}`;
}

export const handleDownloadTemplate = (header: string[]) => {
  const ws = XLSX.utils.json_to_sheet([], {
    header,
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Template");
  XLSX.writeFile(wb, `Excel Template.xlsx`);
};

export const safeTimestamp = (timestamp: unknown): Timestamp | null => {
  if (!timestamp) return null;

  if (timestamp instanceof Date) return Timestamp.fromDate(timestamp);

  if (timestamp instanceof Timestamp) return timestamp;

  // check if the timestamp is a string
  if (typeof timestamp === "string") {
    if (isValidDateString(timestamp)) {
      return Timestamp.fromDate(new Date(timestamp));
    }
  }

  // check if the timestamp is a {seconds: number, nanoseconds: number} object
  if (typeof timestamp === "object") {
    if ("seconds" in timestamp && "nanoseconds" in timestamp) {
      const { seconds, nanoseconds } = timestamp as {
        seconds: number;
        nanoseconds: number;
      };

      return new Timestamp(seconds, nanoseconds);
    }

    if ("_seconds" in timestamp && "_nanoseconds" in timestamp) {
      const { _seconds, _nanoseconds } = timestamp as {
        _seconds: number;
        _nanoseconds: number;
      };

      return new Timestamp(_seconds, _nanoseconds);
    }
  }

  throw new Error(`Invalid timestamp. ${JSON.stringify(timestamp)}`);
};

export const NoResults = () => {
  return (
    <MenuItem disabled={true} text="No results." roleStructure="listoption" />
  );
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getUserPriorityWithOldestDateGroups = (priorityList: User[]) => {
  const authClaims = store.getState().auth.authClaims;

  const isAdminOrHeadDataSpv = isAllowedToSeeAllInvoice(authClaims);
  const groupedUsers = priorityList
    .filter((u) => !u.employee)
    .reduce(
      (acc, user) => {
        // Initialize the priority key if it doesn't exist
        if (!acc[user.priority]) {
          acc[user.priority] = { withNoOldestDate: [], withOldestDate: [] };
        }

        // Check if the user has an 'oldestDate' and categorize accordingly
        if (
          (isAdminOrHeadDataSpv
            ? user.invoicesCount?.noOldestDate ?? 0
            : user.invoicesCount?.noOldestDateNonAdmin ?? 0) === 0 &&
          user.oldestUnresolvedAssumptionDate
        ) {
          acc[user.priority].withOldestDate.push(user);
        } else {
          acc[user.priority].withNoOldestDate.push(user);
        }

        return acc;
      },
      {} as {
        [key: string]: { withNoOldestDate: User[]; withOldestDate: User[] };
      }
    );
  for (const [_, priorityGroup] of Object.entries(groupedUsers)) {
    priorityGroup.withNoOldestDate.sort((a, b) => {
      const noOldestDateDiff =
        (isAdminOrHeadDataSpv
          ? b.invoicesCount?.noOldestDate ?? 0
          : b.invoicesCount?.noOldestDateNonAdmin ?? 0) -
        (isAdminOrHeadDataSpv
          ? a.invoicesCount?.noOldestDate ?? 0
          : a.invoicesCount?.noOldestDateNonAdmin ?? 0);
      if (noOldestDateDiff !== 0) return noOldestDateDiff;
      return (
        (isAdminOrHeadDataSpv
          ? b.invoicesCount?.unresolved ?? 0
          : b.invoicesCount?.unresolvedNonAdmin ?? 0) -
        (isAdminOrHeadDataSpv
          ? a.invoicesCount?.unresolved ?? 0
          : a.invoicesCount?.unresolvedNonAdmin ?? 0)
      );
    });

    priorityGroup.withOldestDate.sort((a, b) => {
      const dateA = a.oldestUnresolvedAssumptionDate
        ? a.oldestUnresolvedAssumptionDate.toDate().getTime()
        : 0;
      const dateB = b.oldestUnresolvedAssumptionDate
        ? b.oldestUnresolvedAssumptionDate.toDate().getTime()
        : 0;
      const dateDiff = dateA - dateB;
      if (dateDiff !== 0) return dateDiff;
      return (
        (isAdminOrHeadDataSpv
          ? b.invoicesCount?.unresolved ?? 0
          : b.invoicesCount?.unresolvedNonAdmin ?? 0) -
        (isAdminOrHeadDataSpv
          ? a.invoicesCount?.unresolved ?? 0
          : a.invoicesCount?.unresolvedNonAdmin ?? 0)
      );
    });
  }

  return groupedUsers;
};
export const getUserPriorityGroups = (priorityList: User[]) => {
  return priorityList
    .filter((u) => !u.employee)
    .reduce(
      (acc, user) => {
        if (!acc[user.priority]) {
          acc[user.priority] = [];
        }
        acc[user.priority].push(user);
        return acc;
      },
      {} as { [key: string]: User[] }
    );
};

export const getPriorityGroupColor = (priority: number) => {
  switch (priority) {
    case 5:
      return "#880015";
    case 4:
      return "#ED1C24";
    case 3:
      return "#FF7F27";
    case 2:
      return "#FFC90E";
    case 1:
      return "#FFF200";
    default:
      return "white";
  }
};
