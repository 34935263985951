import {
  DocumentData,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { DailySale, MunuFile, WastageFile } from "redux/sales/types";
import { db } from "services/firebase";

export function setUserDailySalesListener(
  userId: string,
  lastDoc?: QueryDocumentSnapshot<DocumentData>,
  firstDoc?: QueryDocumentSnapshot<DocumentData>,
  direction?: "next" | "previous"
): EventChannel<{
  data: DailySale[];
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  firstDoc?: QueryDocumentSnapshot<DocumentData>;
}> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const LIMIT = 100;

  let constraints: QueryConstraint[] = [limit(LIMIT)];
  if (direction) {
    constraints =
      direction === "next"
        ? [startAfter(lastDoc), limit(LIMIT)]
        : [endBefore(firstDoc), limitToLast(LIMIT)];
  }

  const userDailySalesQuery = query(
    collection(db, "dailySales"),
    where("deleted", "==", false),
    where("userId", "==", userId),
    orderBy("date", "desc"),
    ...constraints
  );

  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      userDailySalesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => new DailySale(doc.data()));
          const returnedLastDoc =
            snapshot.docs.length !== LIMIT
              ? undefined
              : snapshot.docs[snapshot.docs.length - 1];
          const returnedFirstDoc = !direction ? undefined : snapshot.docs[0];

          emitter({
            data,
            lastDoc: returnedLastDoc,
            firstDoc: returnedFirstDoc,
          });
        }
      }
    );
    return () => unsub();
  });
}

export function setUserSalesListener(
  userId: string,
  showDeletedSales: boolean,
  LIMIT?: number
): EventChannel<object> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const salesRef = collection(db, "munuFiles");

    const salesQuery: Query<DocumentData> =
      showDeletedSales && LIMIT
        ? query(
            salesRef,
            where("userId", "==", userId),
            where("deleted", "==", true),
            orderBy("periodEnd", "desc"),
            limit(LIMIT)
          )
        : query(
            salesRef,
            where("userId", "==", userId),
            where("deleted", "==", false),
            orderBy("periodEnd", "desc")
          );

    const unsub = onSnapshot(
      salesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: MunuFile[] = snapshot.docs.map(
          (x) => new MunuFile(x.id, x.data())
        );
        emitter(data);
      }
    );
    return () => unsub();
  });
}

export function setUserWastagesListener(
  userId: string,
  showDeletedSales: boolean,
  userIntegration: string,
  LIMIT?: number
): EventChannel<object> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const wastagesRef = collection(db, "wastageFiles");

    const wastagesQuery: Query<DocumentData> =
      showDeletedSales && LIMIT
        ? query(
            wastagesRef,
            where("userId", "==", userId),
            where("deleted", "==", true),
            orderBy("periodEnd", "desc"),
            limit(LIMIT)
          )
        : query(
            wastagesRef,
            where("userId", "==", userId),
            where("deleted", "==", false),
            orderBy("periodEnd", "desc")
          );

    const unsub = onSnapshot(
      wastagesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: WastageFile[] = snapshot.docs.map(
          (x) => new WastageFile(x.id, x.data(), userIntegration)
        );
        emitter(data);
      }
    );
    return () => unsub();
  });
}
