import { Button, MenuItem, TagProps } from "@blueprintjs/core";
import {
  ItemRenderer,
  ItemRendererProps,
  MultiSelect,
  Select,
} from "@blueprintjs/select";
import {
  ITEM_TAGS,
  UNINTERESTING_INVOICE_TAGS,
} from "components/invoices/tag.helpers";
import React, { useMemo } from "react";
import { InvoiceHighlightColors, SettingsState } from "redux/settings/types";
import HighlightColorSettings from "./highlight-color";

interface InvoiceProps {
  selectedDefaultItemTags: string[];
  setSelectedSelectedDefaultItemTags: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  selectedUninterestInvoiceTags: string[];
  setSelectedUninterestInvoiceTags: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  selectedSortByField: SettingsState["invoicesSortByField"];
  setSelectedSortByField: React.Dispatch<
    React.SetStateAction<SettingsState["invoicesSortByField"]>
  >;
  highlightColors: InvoiceHighlightColors;
  setHighlightColors: React.Dispatch<
    React.SetStateAction<InvoiceHighlightColors>
  >;
}

export default function Invoices({
  selectedDefaultItemTags,
  setSelectedSelectedDefaultItemTags,
  selectedUninterestInvoiceTags,
  setSelectedUninterestInvoiceTags,
  selectedSortByField,
  setSelectedSortByField,
  highlightColors,
  setHighlightColors,
}: InvoiceProps) {
  const allUninterestingTags = useMemo(
    () =>
      UNINTERESTING_INVOICE_TAGS.sort((a, b) =>
        a.text.toLowerCase().localeCompare(b.text.toLowerCase())
      ),
    [UNINTERESTING_INVOICE_TAGS]
  );

  const allItemTags = useMemo(
    () =>
      [
        ...ITEM_TAGS,
        {
          name: "newAliases",
          text: "New Aliases",
          bgColorClass: "tag-light-green",
        },
      ].sort((a, b) =>
        a.text.toLowerCase().localeCompare(b.text.toLowerCase())
      ),
    [ITEM_TAGS]
  );

  const getItemTagProps = (_value: React.ReactNode, _: number): TagProps => {
    const colorClass = allItemTags.find(
      (item) => item.text === _value
    )?.bgColorClass;
    return colorClass
      ? {
          className: colorClass,
        }
      : {
          interactive: true,
          minimal: true,
        };
  };

  const getUninterestingInvoiceTagProps = (
    _value: React.ReactNode,
    _: number
  ): TagProps => {
    const colorClass = allUninterestingTags.find(
      (item) => item.text === _value
    )?.bgColorClass;
    return colorClass
      ? {
          className: colorClass,
        }
      : {
          interactive: true,
          minimal: true,
        };
  };

  function getItemLabel(tag: string) {
    return allItemTags.find((item) => item.name === tag)?.text;
  }

  function getUninterestingInvoiceTagLabel(tag: string) {
    return allUninterestingTags.find((item) => item.name === tag)?.text;
  }

  const renderItemTags: ItemRenderer<string> = (
    item: string,
    { handleClick }: ItemRendererProps
  ) => {
    if (!item) return null;
    return (
      <MenuItem
        active={selectedDefaultItemTags.some((tag) => tag === item)}
        key={item}
        onClick={handleClick}
        roleStructure="listoption"
        text={getItemLabel(item)}
      />
    );
  };

  const renderselectedUninterestInvoiceTags: ItemRenderer<string> = (
    item: string,
    { handleClick }: ItemRendererProps
  ) => {
    if (!item) return null;
    return (
      <MenuItem
        active={selectedUninterestInvoiceTags.some((tag) => tag === item)}
        key={item}
        onClick={handleClick}
        roleStructure="listoption"
        text={getUninterestingInvoiceTagLabel(item)}
      />
    );
  };

  return (
    <>
      <div className="mb-4">
        Default Invoice Item Tags:
        <MultiSelect
          className="mt-2"
          placeholder="Invoice Item Tags"
          resetOnQuery={true}
          resetOnSelect={true}
          selectedItems={selectedDefaultItemTags}
          tagRenderer={(item) => getItemLabel(item)}
          tagInputProps={{
            tagProps: getItemTagProps,
          }}
          items={allItemTags.map((item) => item.name)}
          itemRenderer={renderItemTags}
          onItemSelect={(tag: string) => {
            selectedDefaultItemTags.some((x) => x === tag)
              ? setSelectedSelectedDefaultItemTags(
                  selectedDefaultItemTags.filter((i) => i !== tag)
                )
              : setSelectedSelectedDefaultItemTags([
                  ...selectedDefaultItemTags,
                  tag,
                ]);
          }}
          onClear={() => setSelectedSelectedDefaultItemTags([])}
          onRemove={(value) =>
            setSelectedSelectedDefaultItemTags(
              selectedDefaultItemTags.filter((i) => i !== value)
            )
          }
        />
      </div>
      <div
        className="mb-4"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Sort invoices by (This will affect both invoices list and users priority
        list):
        <Select
          items={["createdAt", "oldestDate"]}
          filterable={false}
          onItemSelect={(field) => {
            setSelectedSortByField(
              field as SettingsState["invoicesSortByField"]
            );
          }}
          activeItem={selectedSortByField}
          itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={`sort-by-${item}`}
              onClick={handleClick}
              onFocus={handleFocus}
              roleStructure="listoption"
              text={item === "oldestDate" ? "oldestDate (Assumption)" : item}
            />
          )}
        >
          <Button
            text={
              selectedSortByField === "oldestDate"
                ? "oldestDate (Assumption)"
                : selectedSortByField || "createdAt"
            }
            rightIcon="caret-down"
          />
        </Select>
      </div>
      <HighlightColorSettings
        highlightColors={highlightColors}
        setHighlightColors={setHighlightColors}
      />
      <div className="mb-4">
        Uninteresting Invoice Tags:
        <MultiSelect
          className="mt-2"
          placeholder="Uninteresting Invoice Tags"
          resetOnQuery={true}
          resetOnSelect={true}
          selectedItems={selectedUninterestInvoiceTags}
          tagRenderer={(item) => getUninterestingInvoiceTagLabel(item)}
          tagInputProps={{
            tagProps: getUninterestingInvoiceTagProps,
          }}
          items={allUninterestingTags.map((item) => item.name)}
          itemRenderer={renderselectedUninterestInvoiceTags}
          onItemSelect={(tag: string) => {
            selectedUninterestInvoiceTags.some((x) => x === tag)
              ? setSelectedUninterestInvoiceTags(
                  selectedUninterestInvoiceTags.filter((i) => i !== tag)
                )
              : setSelectedUninterestInvoiceTags([
                  ...selectedUninterestInvoiceTags,
                  tag,
                ]);
          }}
          onClear={() => setSelectedUninterestInvoiceTags([])}
          onRemove={(value) =>
            setSelectedUninterestInvoiceTags(
              selectedUninterestInvoiceTags.filter((i) => i !== value)
            )
          }
        />
      </div>
    </>
  );
}
