import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { GeminiPrompt } from "redux/prompts/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function createGeminiPrompt(
  prompt: string,
  category: "invoice" | "transaction",
  createdBy: string,
  supplierId?: string
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!db) return { data: null, error: "No db" };
  const collRef = collection(db, "geminiPrompts");

  try {
    const payload: Record<string, any> = {
      prompt: prompt,
      category: category,
      createdAt: new Date(),
      active: false,
      deleted: false,
      createdBy: createdBy,
      updatedAt: new Date(),
      supplierId: supplierId || "",
    };
    await addDoc(collRef, payload);

    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function updateGeminiPrompt(
  id: string,
  payload: Partial<GeminiPrompt>
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const { active = false, category, prompt, supplierId = "" } = payload;
  if (!id || !prompt || !category)
    return { data: null, error: "Missing required fields" };

  if (!db) return { data: null, error: "No db" };
  const docRef = doc(db, "geminiPrompts", id);

  try {
    const allActiveInCategory = await getDocs(
      query(
        collection(db, "geminiPrompts"),
        where("active", "==", true),
        where("category", "==", category)
      )
    );

    const batch = writeBatch(db);

    if (active && allActiveInCategory.size > 0 && !supplierId) {
      for (const doc of allActiveInCategory.docs) {
        const { supplierId } = doc.data();
        if (supplierId) continue;
        batch.update(doc.ref, {
          active: false,
          updatedAt: new Date(),
        });
      }
    }

    if (supplierId) {
      if (active) {
        const allActiveInSupplier = await getDocs(
          query(
            collection(db, "geminiPrompts"),
            where("active", "==", true),
            where("supplierId", "==", supplierId)
          )
        );

        if (allActiveInSupplier.size > 0) {
          for (const doc of allActiveInSupplier.docs)
            batch.update(doc.ref, {
              active: false,
              updatedAt: new Date(),
            });
        }
      }
    }

    batch.update(docRef, { ...payload, updatedAt: new Date() });
    await batch.commit();
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function getActiveSupplierPrompts() {
  if (!db) return { data: null, error: "No db" };
  const allActivePrompts = await getDocs(
    query(
      collection(db, "geminiPrompts"),
      where("active", "==", true),
      where("deleted", "==", false),
      where("category", "==", "invoice")
    )
  );

  const result: GeminiPrompt[] = [];
  for (const doc of allActivePrompts.docs) {
    const data = doc.data();
    result.push(new GeminiPrompt(doc.id, data));
  }

  return { data: result, error: null };
}
