import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import {
  setUserDailySalesListener,
  setUserSalesListener,
  setUserWastagesListener,
} from "services/listeners/sales";
import {
  getDailySales,
  getFirstAndLastSaleId,
  getUserSales,
  updateSalesData,
} from "services/sales";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { MunuFile, WastageFile } from "./types";

export interface GET_USER_SALES_payload {
  userId: string;
  direction?: "next" | "previous";
  firstSaleId?: string;
  lastSaleId?: string;
}

export function* GET_USER_SALES(
  input: ReturnType<typeof actions.GET_USER_SALES>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSales: true,
    })
  );

  if (input.payload.direction) {
    if (input.payload.direction === "next" && input.payload.lastSaleId) {
      const result: ServiceReturn = yield call(
        getUserSales,
        userId,
        input.payload.lastSaleId,
        input.payload.direction
      );
      if (result.data) {
        yield put(
          actions.SET_STATE({
            sales: result.data,
            loadingSales: false,
          })
        );
      } else {
        yield put(actions.SET_STATE({ loadingSales: false }));
        yield put(
          alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
        );
      }
    } else if (
      input.payload.direction === "previous" &&
      input.payload.firstSaleId
    ) {
      const result: ServiceReturn = yield call(
        getUserSales,
        userId,
        input.payload.firstSaleId,
        input.payload.direction
      );
      if (result.data) {
        yield put(
          actions.SET_STATE({
            sales: result.data,
            loadingSales: false,
          })
        );
      } else {
        yield put(actions.SET_STATE({ loadingSales: false }));
        yield put(
          alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
        );
      }
    }
    return;
  }

  const firstAndLastSale: ServiceReturn = yield call(
    getFirstAndLastSaleId,
    userId
  );

  const result: ServiceReturn = yield call(getUserSales, userId);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        sales: result.data,
        loadingSales: false,
        firstSaleId: firstAndLastSale.data?.firstSaleId,
        lastSaleId: firstAndLastSale.data?.lastSaleId,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingSales: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

/**
 * Type of `GET_SCRAPERS` payload
 * @category Integration Monitor
 */ export interface SUBSCRIBE_TO_USER_DAILY_SALES_payload {
  /** Selected user ID */
  userId: string;
  direction?: "next" | "previous";
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  firstDoc?: QueryDocumentSnapshot<DocumentData>;
}

/**
 * Saga channel to listen scrapers log data from firestore.
 * After call service to create listener of scrapers log data, this saga will listen into data stream of scrapers logs data.
 * Listened data will stored in redux state.
 * @generator
 * @param input Parameter accepted by generator function
 * @yields {void}
 * @example
 * // Import the action first
 * import actionsIM from "redux/integration-monitor/actions";
 *
 * dispatch(
 *   actionsIM.SUBSCRIBE_TO_USER_DAILY_SALES({
 *     userId, type, direction, dateFrom,
 *   });
 * )
 */
function* SUBSCRIBE_TO_USER_DAILY_SALES(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_DAILY_SALES>
) {
  const { userId, lastDoc, firstDoc, direction } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUserDailySales: true,
      userDailySales: {
        data: [],
      },
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setUserDailySalesListener,
    userId,
    lastDoc,
    firstDoc,
    direction
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_DAILY_SALES);
    channel.close();
    yield put(
      actions.SET_STATE({
        userDailySales: { data: [] },
      })
    );
  });

  try {
    while (true) {
      const { data, lastDoc, firstDoc } = yield take(channel);
      yield put(
        actions.SET_STATE({
          userDailySales: { data, lastDoc, firstDoc },
          loadingUserDailySales: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingUserDailySales: false }));
    }
  }
}

export interface GET_DAILY_SALES_payload {
  userId: string;
  date: Date;
}
export function* GET_DAILY_SALES({
  payload,
}: ReturnType<typeof actions.GET_DAILY_SALES>) {
  const { userId, date } = payload;
  yield put(
    actions.SET_STATE({
      loadingDailySales: true,
    })
  );
  const result: ServiceReturn = yield call(getDailySales, userId, date);

  if (result.data) {
    yield put(
      actions.SET_STATE({
        dailySale: result.data,
        loadingDailySales: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingDailySales: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}
export interface SUBSCRIBE_USER_SALES_payload {
  userId: string;
  showDeletedSales: boolean;
  limit?: number;
}
export function* SUBSCRIBE_USER_SALES(
  input: ReturnType<typeof actions.SUBSCRIBE_USER_SALES>
) {
  const { userId, limit, showDeletedSales } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSales: true,
      sales: [],
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setUserSalesListener,
    userId,
    showDeletedSales,
    limit
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_USER_SALES);
    channel.close();
  });

  try {
    while (true) {
      const result: MunuFile[] = yield take(channel);
      if (result) {
        if (showDeletedSales)
          yield put(
            actions.SET_STATE({
              loadingSales: false,
              sales: result,
            })
          );
        else
          yield put(
            actions.SET_STATE({
              loadingSales: false,
              notDeletedSales: result,
            })
          );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingSales: false,
        })
      );
    }
  }
}

export interface SUBSCRIBE_USER_WASTAGES_payload {
  userId: string;
  showDeletedWastages: boolean;
  userIntegration: string;
  limit?: number;
}
export function* SUBSCRIBE_USER_WASTAGES(
  input: ReturnType<typeof actions.SUBSCRIBE_USER_WASTAGES>
) {
  const { userId, limit, showDeletedWastages, userIntegration } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingWastages: true,
      wastages: [],
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setUserWastagesListener,
    userId,
    showDeletedWastages,
    userIntegration,
    limit
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_USER_WASTAGES);
    channel.close();
  });

  try {
    while (true) {
      const result: WastageFile[] = yield take(channel);
      if (result) {
        if (showDeletedWastages)
          yield put(
            actions.SET_STATE({
              loadingWastages: false,
              wastages: result,
            })
          );
        else
          yield put(
            actions.SET_STATE({
              loadingWastages: false,
              notDeletedWastages: result,
            })
          );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingSales: false,
        })
      );
    }
  }
}

export interface UPDATE_SALES_DATA_Payload {
  userId: string;
  salesId: string;
  data: Partial<MunuFile>;
}

export function* UPDATE_SALES_DATA(
  input: ReturnType<typeof actions.UPDATE_SALES_DATA>
) {
  const { userId, salesId, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingDelete: true,
    })
  );

  const result: ServiceReturn = yield call(updateSalesData, salesId, data);

  if (result.data) {
    yield put(alertActions.SUCCESS("Sales data updated successfully"));
    yield put(actions.GET_USER_SALES({ userId }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
  yield put(actions.SET_STATE({ loadingDelete: false }));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USER_SALES, GET_USER_SALES),
    takeLatest(actions.UPDATE_SALES_DATA, UPDATE_SALES_DATA),
    takeLatest(actions.SUBSCRIBE_USER_SALES, SUBSCRIBE_USER_SALES),
    takeLatest(
      actions.SUBSCRIBE_TO_USER_DAILY_SALES,
      SUBSCRIBE_TO_USER_DAILY_SALES
    ),
    takeLatest(actions.GET_DAILY_SALES, GET_DAILY_SALES),
    takeLatest(actions.SUBSCRIBE_USER_WASTAGES, SUBSCRIBE_USER_WASTAGES),
  ]);
}
