import { DateRange } from "@blueprintjs/datetime2";
import {
  DocumentData,
  DocumentReference,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getBlob, getDownloadURL, ref } from "firebase/storage";
import { getAuthUserID } from "redux/auth/helpers";
import { ServiceReturn } from "redux/types";
import {
  ConfirmedStatus,
  IssueLogTagSuggestion,
  StockReportData,
  User,
} from "redux/users/types";
import { checkCounter } from "services/counter";
import { db, functions, storage } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { SERVER_COUNTS } from "utils/constants";

export async function getUser(uid: string): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const userRef = doc(db, "users", uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    return { data: { ...userDoc.data(), id: userDoc.id }, error: null };
  } else {
    return { data: null, error: "No such document!" };
  }
}

export async function getUsers(loadAllUsers: boolean) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const activeUsersCondition = [
    where("active", "==", true),
    where("organization", "==", false),
    where("employee", "==", false),
  ];
  const queryConstraints = [
    ...(loadAllUsers ? [] : activeUsersCondition),
    orderBy("name"),
  ];

  const data: DocumentData[] = [];

  const querySnapshot = await getDocs(
    query(collection(db, "users"), ...queryConstraints)
  );
  for (const doc of querySnapshot.docs) {
    data.push(new User(doc.id, doc.ref.path, doc.data()));
  }

  return data;
}

export async function updateUser(
  data: DocumentData,
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    await updateDoc(doc(db, "users", userId), data);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function batchUpdateUsers(
  data: DocumentData,
  usersArray: string[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const batch = writeBatch(db);
  usersArray.forEach(async (user) => {
    if (!db) return;
    const userRef = doc(db, "users", user);
    batch.update(userRef, {
      [data.attr]: data.value,
    });
  });

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function dismissUserCount(
  userId: string,
  countId: string,
  userStatusNote: { data: string; archived: boolean }[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const batch = writeBatch(db);
  const countRef = doc(db, "users/" + userId + "/counts/" + countId);
  const userRef = doc(db, "users/" + userId);
  batch.update(countRef, {
    dismissed: true,
  });
  batch.update(userRef, {
    userStatusNote:
      userStatusNote.length > 0
        ? userStatusNote.map((note) => ({
            ...note,
            archived: true,
          }))
        : [],
    userStatusNoteTags: [],
  });
  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchDismissUserCount(
  usersArray: User[],
  state: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    let batch = writeBatch(db);
    let operationCount = 0;
    const MAX_BATCH_SIZE = 150;
    for (const user of usersArray) {
      if (!user.activeCounts || user.activeCounts.length === 0) continue;

      for (const activeCount of user.activeCounts) {
        const countId = activeCount.countId;
        const countState = activeCount.state;
        if (!countId || countState !== state) continue;

        const countRef = doc(db, "users/" + user.id + "/counts/" + countId);
        batch.update(countRef, {
          dismissed: true,
        });
        operationCount++;

        if (operationCount === MAX_BATCH_SIZE) {
          await batch.commit();
          batch = writeBatch(db);
          operationCount = 0;
        }
      }

      const userRef = doc(db, "users/" + user.id);
      batch.update(userRef, {
        activeCounts: user.activeCounts
          .filter((x) => x.state === state)
          .map((activeCount) => ({
            ...activeCount,
            dismissed: true,
          })),
        userStatusNote:
          user.userStatusNote && user.userStatusNote.length > 0
            ? user.userStatusNote.map((note) => ({ ...note, archived: true }))
            : [],
      });
      operationCount++;

      if (operationCount === MAX_BATCH_SIZE) {
        await batch.commit();
        batch = writeBatch(db);
        operationCount = 0;
      }
    }

    if (operationCount > 0) {
      await batch.commit();
    }

    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function updateCountConfirmedStatus(
  userId: string,
  countId: string,
  data: ConfirmedStatus,
  lockCount?: boolean
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const payload: DocumentData = {
    confirmedStatus: data,
  };

  if (lockCount) {
    payload.locked = true;
  }

  return await updateDoc(
    doc(db, "users/" + userId + "/counts/" + countId),
    payload
  )
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateUserConfirmedStatus(
  userId: string,
  data: ConfirmedStatus
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(doc(db, "users/" + userId), {
    confirmedStatus: data,
  })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateTransactionsResolved(
  transactionRefs: { ref: DocumentReference }[],
  resolved: boolean,
  resolvedBy: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  // Get a new write batch
  const batch = writeBatch(db);
  transactionRefs.forEach((transactionRef) => {
    batch.update(transactionRef.ref, {
      resolved: resolved,
      resolvedBy: resolvedBy,
    });
  });

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateNoteSuggestions(
  newNoteSuggestion: string | IssueLogTagSuggestion,
  docName: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  // Get a new write batch
  const ref = doc(db, "adminPanelSettings", docName);
  const document = await getDoc(ref);
  const isExists = document.exists();
  if (!isExists)
    return await setDoc(ref, { data: [newNoteSuggestion] })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
  else
    return await updateDoc(ref, {
      data: arrayUnion(newNoteSuggestion),
    })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
}

export async function editNoteSuggestions(
  newNoteSuggestionsArr: (string | IssueLogTagSuggestion)[],
  docName: string
) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const ref = doc(db, "adminPanelSettings", docName);
    await setDoc(ref, { data: newNoteSuggestionsArr });
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function getUserTransactionFile(
  path: string
): Promise<ServiceReturn> {
  if (!storage) return { data: null, error: "No storage connection" };

  try {
    const fileRef = ref(storage, path);
    const file = await getBlob(fileRef);

    // convert as text
    const text = await file.text();

    const json = JSON.parse(text);
    if (json) {
      return { data: null, error: json.message || "No data" };
    }

    throw new Error("continue");
  } catch (err) {
    const downloadURL = await getDownloadURL(ref(storage, path));

    return {
      data: downloadURL,
      error: null,
    };
  }
}

export async function getUserHistory(userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const querySnapshot = await getDocs(
    collection(db, "users", userId, "userHistory")
  );

  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });

  return data;
}

export async function addUserHistory(
  data: DocumentData,
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const historyRef = collection(db, "users", userId, "userHistory");
  return await addDoc(historyRef, data)
    .then((docRef) => {
      return { data: docRef.id, error: null };
    })
    .catch((error) => {
      return { data: null, error: error };
    });
}

export async function getTableColumns(
  module: string
): Promise<ServiceReturn<Record<string, number>>> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const userId = getAuthUserID();
  if (userId) {
    const docRef = doc(db, "users", userId, "tableColumns", module);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { data: docSnap.data(), error: null };
    } else {
      setDoc(doc(db, "users", userId, "tableColumns", module), {});
      return { data: null, error: null };
    }
  } else {
    return { data: null, error: null };
  }
}

export async function editTableColumns(
  data: Record<string, number>,
  module: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const userId = getAuthUserID();
    if (!userId) return { data: null, error: "No user ID" };
    await setDoc(doc(db, "users", userId, "tableColumns", module), data);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getChecklistItems(userId: string, countId: string) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const docRef = doc(
      db,
      "users",
      userId,
      "counts",
      countId,
      "checklist",
      "--checklist"
    );

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) return { data: docSnapshot.data(), error: null };
    else return { data: null, error: "Document does not exist" };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getStockCountData(userId: string, countId: string) {
  if (!functions) return { data: null, error: "No functions connection" };
  try {
    const getStockCountData = httpsCallable<
      { userId: string; countId: string },
      StockReportData
    >(functions, CALLABLE_FUNCTIONS.getStockCountData, { timeout: 540000 });
    const resp = await getStockCountData({ userId, countId });

    if (resp.data) {
      return { data: resp.data, error: null };
    } else {
      return { data: null, error: "No data" };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getUserDocumentHistoryLogs(
  userId: string,
  selDateRangeFilter?: DateRange,
  typeRange?: string,
  lastDocId?: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const constraints = [];

  if (lastDocId) {
    const docRef = doc(db, "users", userId, "documentHistoryLogs", lastDocId);
    const lastDoc = await getDoc(docRef);
    constraints.push(startAfter(lastDoc));
  }

  if (typeRange && typeRange !== "All") {
    constraints.push(where("entityType", "==", typeRange));
  }

  if (selDateRangeFilter) {
    const [startDate, endDate] = selDateRangeFilter;
    if (startDate && endDate) {
      endDate.setHours(23, 59, 59, 999);
      constraints.push(
        where("createdAt", ">=", startDate),
        where("createdAt", "<=", endDate)
      );
    }
  }

  const querySnapshot = await getDocs(
    query(
      collection(db, "users", userId, "documentHistoryLogs"),
      orderBy("createdAt", "desc"),
      ...constraints,
      limit(50)
    )
  );

  for (const doc of querySnapshot.docs) {
    data.push({ ...doc.data(), id: doc.id });
  }

  return { data, error: null };
}

export async function writeInvoiceCount() {
  if (!functions) return { data: null, error: "No functions connection" };

  const writeInvoiceCount = httpsCallable(
    functions,
    CALLABLE_FUNCTIONS.writeInvoiceCountCallable,
    { timeout: 540000 }
  );

  const resp = await writeInvoiceCount();

  if (resp.data) {
    return { data: true, error: null };
  }

  return { data: null, error: "No data" };
}

export async function addActivityNote(
  userId: string,
  content: string,
  countId: string,
  authUserId: string
) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const userRef = doc(db, "users", userId);

    const newActivity = {
      userId: userId,
      countId: countId,
      type: "NOTE",
      content: content,
      deleted: false,
      createdBy: authUserId,
      createdAt: new Date(),
    };

    await addDoc(collection(userRef, "activities"), newActivity);
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function updateActivityNote(
  userId: string,
  activityId: string,
  content: string,
  authUserId: string
) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const activityRef = doc(db, "users", userId, "activities", activityId);

    await updateDoc(activityRef, {
      content: content,
      editedBy: authUserId,
      updatedAt: new Date(),
    });
    return { data: true, error: "No data" };
  } catch (error) {
    return { data: false, error: error };
  }
}

export async function deleteActivityNote(
  userId: string,
  activityId: string,
  authUserId: string
) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const activityRef = doc(db, "users", userId, "activities", activityId);

    await updateDoc(activityRef, {
      deleted: true,
      editedBy: authUserId,
      updatedAt: new Date(),
    });
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}
