import { DocumentData, doc, onSnapshot } from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setOTPMessageListener(): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const docRef = doc(db, "adminPanelSettings", "otpMessages");
    const unsub = onSnapshot(docRef, (doc) => {
      emitter(doc.data() ?? {});
    });

    return () => unsub();
  });
}

export function setOTPListener(): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const docRef = doc(db, "adminPanelSettings", "otp");
    const unsub = onSnapshot(docRef, (doc) => {
      emitter(doc.data() ?? {});
    });

    return () => unsub();
  });
}
