import { all } from "redux-saga/effects";
import accessLevels from "./access-levels/sagas";
import apTransactions from "./ap-transactions/sagas";
import auth from "./auth/sagas";
import appConfig from "./config/sagas";
import counts from "./counts/sagas";
import docs from "./docs/sagas";
import integrationMonitor from "./integration-monitor/sagas";
import integrations from "./integrations/sagas";
import invoices from "./invoice/sagas";
import items from "./items/sagas";
import materialBookkeepingItems from "./material-bookkeeping-items/sagas";
import notificationCentre from "./notification-centre/sagas";
import invoiceOcr from "./ocr/sagas";
import posItemTasks from "./pos-item-tasks/sagas";
import prompts from "./prompts/sagas";
import sales from "./sales/sagas";
import scripts from "./scripts/sagas";
import settingSaga from "./settings/sagas";
import silhouettes from "./silhouettes/sagas";
import suppliers from "./suppliers/sagas";
import userPriorities from "./user-priorities/sagas";
import users from "./users/sagas";

export default function* rootSaga() {
  yield all([
    auth(),
    appConfig(),
    users(),
    invoices(),
    suppliers(),
    integrationMonitor(),
    invoiceOcr(),
    apTransactions(),
    accessLevels(),
    userPriorities(),
    counts(),
    docs(),
    settingSaga(),
    posItemTasks(),
    items(),
    notificationCentre(),
    scripts(),
    sales(),
    silhouettes(),
    integrations(),
    materialBookkeepingItems(),
    prompts(),
  ]);
}
