import { createActions } from "redux/create-actions";
import {
  GET_USER_COUNTS_Payload,
  GET_USER_COUNT_AREAS_payload,
  UPDATE_USER_OTP_payload,
} from "./sagas";
import { ScriptState } from "./types";

const actions = createActions("scripts", {
  SET_STATE: {} as Partial<ScriptState>,
  GET_USER_COUNTS: {} as GET_USER_COUNTS_Payload,
  GET_USER_COUNT_AREAS: {} as GET_USER_COUNT_AREAS_payload,
  GET_USER_OTP: undefined,
  UPDATE_USER_OTP: {} as UPDATE_USER_OTP_payload,
  SUBSCRIBE_TO_OTP_MESSAGES: undefined,
  UNSUBSCRIBE_FROM_OTP_MESSAGES: undefined,
  SUBSCRIBE_TO_OTP: undefined,
  UNSUBSCRIBE_FROM_OTP: undefined,
});

export default actions;
