import { Dispatch, SetStateAction } from "react";
import { IChecklistGroup } from "../..";
import { Button, Icon, InputGroup, MenuItem, Tooltip } from "@blueprintjs/core";
import { IAppTutorial } from "components/app-tutorials/types";
import { WithId } from "@stockifi/shared";
import { ItemRenderer, ItemRendererProps, Select } from "@blueprintjs/select";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";

interface Props {
  index: number;
  group: IChecklistGroup;
  newGroups: IChecklistGroup[];
  setNewGroups: Dispatch<SetStateAction<IChecklistGroup[]>>;
  tutorials: WithId<IAppTutorial>[];
}

const NewGroup = ({
  index,
  group,
  newGroups,
  setNewGroups,
  tutorials,
}: Props) => {
  const unselectedTutorials = tutorials.filter(
    (t) => !newGroups[index].tutorials.some((id) => id === t.id)
  );

  const render: ItemRenderer<WithId<IAppTutorial>> = (
    item,
    { handleClick, handleFocus, modifiers }: ItemRendererProps
  ) => (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      shouldDismissPopover={false}
      text={item.title || item.id}
    />
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Group {index + 1}</p>
        <ActionConfirmationDialogue
          title=""
          text="Are you sure you want to remove this group?"
          onConfirm={() => {
            const newGroupsCopy = [...newGroups];
            newGroupsCopy.splice(index, 1);
            setNewGroups(newGroupsCopy);
          }}
        >
          <Tooltip content="Delete group">
            <Icon icon="cross" style={{ cursor: "pointer" }} />
          </Tooltip>
        </ActionConfirmationDialogue>
      </div>
      <InputGroup
        placeholder="Group title"
        value={group.title}
        onChange={(e) => {
          const newGroupsCopy = [...newGroups];
          newGroupsCopy[index].title = e.target.value;
          setNewGroups(newGroupsCopy);
        }}
        style={{ marginBottom: 10 }}
      />
      <Select<WithId<IAppTutorial>>
        filterable
        itemPredicate={(query, item) =>
          item.id.toLowerCase().includes(query.toLowerCase()) ||
          item.name.toLowerCase().includes(query.toLowerCase())
        }
        items={unselectedTutorials}
        itemRenderer={render}
        onItemSelect={(item) => {
          const newGroupsCopy = [...newGroups];
          newGroupsCopy[index].tutorials.push(item.id);
          setNewGroups(newGroupsCopy);
        }}
        popoverProps={{
          matchTargetWidth: true,
          minimal: true,
        }}
      >
        <Button
          text={"Add tutorial"}
          alignText="left"
          rightIcon="double-caret-vertical"
          className="w-100 mt-2"
        />
      </Select>
      <div style={{ marginTop: 10 }}>
        <DndContext
          onDragEnd={(event) => {
            const { active, over } = event;
            if (active?.id !== over?.id) {
              const dragIndex = group.tutorials.findIndex(
                (id) => id === active?.id
              );
              const hoverIndex = group.tutorials.findIndex(
                (id) => id === over?.id
              );
              const items = arrayMove(group.tutorials, dragIndex, hoverIndex);
              const newGroupsCopy = [...newGroups];
              newGroupsCopy[index].tutorials = items;
              setNewGroups(newGroupsCopy);
            }
          }}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={group.tutorials}>
            {group.tutorials?.map((tutorial) => {
              const tut = tutorials.find((t) => t.id === tutorial);
              if (!tut) return null;
              return (
                <Card
                  key={tut.id}
                  tutorial={tut}
                  index={index}
                  newData={newGroups}
                  setNewData={setNewGroups}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default NewGroup;

interface CardProps {
  tutorial: WithId<IAppTutorial>;
  index: number;
  newData: IChecklistGroup[];
  setNewData: Dispatch<SetStateAction<IChecklistGroup[]>>;
}

const Card = ({ tutorial, index, newData, setNewData }: CardProps) => {
  const { listeners, attributes, setNodeRef, transition, transform } =
    useSortable({
      id: tutorial.id,
    });
  return (
    <div
      ref={setNodeRef}
      key={tutorial.id}
      style={{
        border: "1px solid white",
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "5px 0",
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Icon
          icon="drag-handle-vertical"
          style={{ cursor: "move" }}
          {...listeners}
          {...attributes}
        />
        <span>{tutorial.title || tutorial.id}</span>
        {tutorial.deleted && <span style={{ color: "red" }}>Deleted</span>}
      </div>
      <Icon
        icon="cross"
        style={{ cursor: "pointer" }}
        onClick={() => {
          const newGroupsCopy = [...newData];
          const newTutorials = newGroupsCopy[index].tutorials?.filter(
            (t) => t !== tutorial.id
          );
          newGroupsCopy[index].tutorials = newTutorials;
          setNewData(newGroupsCopy);
        }}
      />
    </div>
  );
};
