import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

import { EventChannel, eventChannel } from "redux-saga";
import { GeminiPrompt } from "redux/prompts/types";
import { db } from "services/firebase";

export function setGeminiPromptsListener(): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const collRef = query(
      collection(db, "geminiPrompts"),
      orderBy("createdAt", "desc")
    );
    const unsub = onSnapshot(
      collRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: DocumentData[] = [];

        for (const doc of snapshot.docs) {
          data.push(new GeminiPrompt(doc.id, doc.data()));
        }

        emitter(data);
      }
    );

    return () => unsub();
  });
}
