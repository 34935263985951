import { Button, Dialog, DialogBody, InputGroup } from "@blueprintjs/core";
import { WithId } from "@stockifi/shared";
import { IAppTutorial } from "components/app-tutorials/types";
import { useState } from "react";
import { IChecklist, IChecklistGroup } from "..";
import { updateChecklist } from "services/app-tutorials";
import { useAppDispatch } from "redux/hooks";
import actions from "redux/alert/actions";
import NewGroup from "../add-new-checklist/new-group";

interface Props {
  tutorials: WithId<IAppTutorial>[];
  checklist: IChecklist;
  fetchChecklists: () => void;
}

const EditChecklist = ({ tutorials, checklist, fetchChecklists }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [groups, setGroups] = useState<IChecklistGroup[]>(checklist.groups);
  const [saveLoading, setSaveLoading] = useState(false);
  const [title, setTitle] = useState(checklist.title);

  const handleOnOpen = () => {
    setOpenDialog(true);
    setGroups(checklist.groups);
    setTitle(checklist.title);
  };

  const handleOnClose = () => {
    setOpenDialog(false);
  };

  const handleAddNewGroup = () => {
    setGroups([...groups, { title: "", tutorials: [] }]);
  };

  const handleSaveChecklist = async () => {
    setSaveLoading(true);
    await updateChecklist(checklist.id, {
      ...checklist,
      title,
      groups,
    })
      .then(() => {
        handleOnClose();
        fetchChecklists();
        dispatch(actions.SUCCESS("Checklist updated successfully"));
      })
      .catch((err) => {
        dispatch(actions.ERROR(`Error updating checklist: ${err}`));
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  return (
    <>
      <Button onClick={handleOnOpen}>Edit Checklist</Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={handleOnClose}
        title="Edit Checklist"
        style={{
          maxHeight: "80vh",
        }}
      >
        <DialogBody>
          <InputGroup
            fill
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Checklist title"
            style={{ marginBottom: 20 }}
          />
          <Button onClick={handleAddNewGroup}>Add new group</Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              margin: "20px 0",
            }}
          >
            {groups.map((group, index) => (
              <NewGroup
                key={index}
                index={index}
                group={group}
                newGroups={groups}
                setNewGroups={setGroups}
                tutorials={tutorials}
              />
            ))}
          </div>
          <Button
            fill
            disabled={!title || groups.length === 0}
            loading={saveLoading}
            onClick={handleSaveChecklist}
          >
            Save Changes
          </Button>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default EditChecklist;
