import { combineReducers } from "redux";
import accessLevels from "./access-levels/reducers";
import alert from "./alert/reducers";
import apTransactions from "./ap-transactions/reducers";
import auth from "./auth/reducers";
import appConfig from "./config/reducers";
import counts from "./counts/reducers";
import docs from "./docs/reducers";
import integrationMonitor from "./integration-monitor/reducers";
import integrations from "./integrations/reducers";
import invoices from "./invoice/reducers";
import items from "./items/reducers";
import materialBookkeepingItems from "./material-bookkeeping-items/reducers";
import notificationCentre from "./notification-centre/reducers";
import invoiceOcr from "./ocr/reducers";
import posItemTasks from "./pos-item-tasks/reducers";
import prompts from "./prompts/reducers";
import sales from "./sales/reducers";
import scripts from "./scripts/reducers";
import settings from "./settings/reducers";
import silhouettes from "./silhouettes/reducers";
import suppliers from "./suppliers/reducers";
import userPriorities from "./user-priorities/reducers";
import users from "./users/reducers";

const rootReducer = () =>
  combineReducers({
    auth,
    appConfig,
    users,
    invoices,
    suppliers,
    integrationMonitor,
    invoiceOcr,
    apTransactions,
    accessLevels,
    userPriorities,
    counts,
    docs,
    settings,
    posItemTasks,
    items,
    notificationCentre,
    scripts,
    alert,
    sales,
    silhouettes,
    integrations,
    materialBookkeepingItems,
    prompts,
  });

export default rootReducer;
