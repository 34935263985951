import { createActions } from "redux/create-actions";
import {
  GET_CALENDAR_DATA_Payload,
  GET_DATA_BY_DATAID_Payload,
  GET_DATA_BY_USERID_Payload,
  GET_IMAGES_Payload,
  GET_IMAGE_Payload,
  GET_IM_METRICS_Payload,
  GET_POS_FILES_BY_USERID_Payload,
  GET_SCRAPERS_Payload,
  GET_SCRAPER_LOGS_Payload,
  GET_SCRAPER_LOG_BY_ID_Payload,
  UPDATE_LOG_NOTIFICATION_Payload,
} from "./sagas";
import { IntegrationMonitorState, ScrapersData } from "./types";

const actions = createActions("integrationMonitor", {
  SET_STATE: {} as Partial<IntegrationMonitorState>,
  GET_DATA_BY_DATAID: {} as GET_DATA_BY_DATAID_Payload,
  GET_IMAGES: {} as GET_IMAGES_Payload,
  GET_IMAGE: {} as GET_IMAGE_Payload,
  SUBSCRIBE_TO_SCRAPERS_LOGS: {} as GET_SCRAPERS_Payload,
  GET_SCRAPER_LOG_BY_ID: {} as GET_SCRAPER_LOG_BY_ID_Payload,
  GET_SCRAPER_LOGS: {} as GET_SCRAPER_LOGS_Payload,
  UNSUBSCRIBE_FROM_SCRAPERS_LOGS: undefined,
  UPDATE_SCRAPERS_DATA_STATE: {} as Record<string, ScrapersData>,
  UPDATE_FILES_DATA_STATE: {} as IntegrationMonitorState["images"],
  ADD_LOADING_STATE: "" as string,
  REMOVE_LOADING_STATE: "" as string,
  UPDATE_LOG_NOTIFICATION: {} as UPDATE_LOG_NOTIFICATION_Payload,
  GET_DATA_BY_USERID: {} as GET_DATA_BY_USERID_Payload,
  GET_POS_FILES_BY_USERID: {} as GET_POS_FILES_BY_USERID_Payload,
  GET_INTEGRATIONS_FLAGS: undefined,
  SUBSCRIBE_TO_SCRAPERS_LOGS_BY_USERID: {} as GET_SCRAPER_LOGS_Payload,
  UNSUBSCRIBE_FROM_SCRAPERS_LOGS_BY_USERID: undefined,
  SUBSCRIBE_TO_IM_METRICS: {} as GET_IM_METRICS_Payload,
  UNSUBSCRIBE_FROM_IM_METRICS: undefined,
  SUBSCRIBE_CALENDAR_DATA: {} as GET_CALENDAR_DATA_Payload,
  UNSUBSCRIBE_CALENDAR_DATA: undefined,
});

export default actions;
