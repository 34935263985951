import { PDFDocumentProxy } from "pdfjs-dist";
import { SetStateAction } from "react";
import {
  APImageData,
  APTransaction,
  ClassificationCount,
} from "redux/ap-transactions/types";
import { AppDispatch } from "redux/store";
import store from "redux/store";
import usersActions from "redux/users/actions";
import { getUserName } from "redux/users/helpers";

export async function convertFromPdf(f: string) {
  const pdfjsLib = require("pdfjs-dist/webpack");
  const pdf: PDFDocumentProxy = await pdfjsLib.getDocument(f).promise;
  const pdfPages = await toImageData(pdf);

  const pages = await Promise.all(
    pdfPages.map(async (x, page) => {
      if (!x) return null;

      const canvas = document.createElement("canvas");
      canvas.width = x.width;
      canvas.height = x.height;
      const ctx = canvas.getContext("2d");
      ctx?.putImageData(x, 0, 0);

      const blob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob((blob) => resolve(blob));
      });

      if (!blob) return null;

      const reader = new FileReader();
      reader.readAsDataURL(blob);

      return await new Promise<APImageData>((resolve) => {
        reader.onloadend = () =>
          resolve({ source: f, url: window.URL.createObjectURL(blob), page });
      });
    })
  );
  return pages.filter((x) => x !== null) as APImageData[];
}

async function toImageData(pdf: PDFDocumentProxy) {
  const pdfPages = [];
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale: 1.5 });
    const canvas = document.createElement("canvas");
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) continue;
    const renderContext = { canvasContext: ctx, viewport: viewport };

    await page.render(renderContext).promise;
    pdfPages.push(ctx.getImageData(0, 0, canvas.width, canvas.height));
  }
  return pdfPages;
}

export const isPdf = (fileUrl: string) => {
  return fileUrl.split(".").pop()?.split("?")[0].toLocaleLowerCase() === "pdf";
};

const isImage = (fileUrl: string) => {
  const extension = fileUrl.split(".").pop()?.split("?")[0].toLocaleLowerCase();
  return extension === "jpg" || extension === "jpeg" || extension === "png";
};

/**
 * Change invoice data to AP Image Data format.
 * It will convert the invoice to image if the original file is in PDF.
 * @param {string} fileUrl the URL of the file to be processed
 * @returns {Promise<APImageData[]>} An array of image data for the file
 * @category AP Transactions
 */
export async function processFile(fileUrl: string): Promise<APImageData[]> {
  if (!fileUrl) return [];
  if (isPdf(fileUrl)) {
    try {
      const result = await convertFromPdf(fileUrl);
      return result;
    } catch (error) {
      return [{ source: fileUrl, url: "", page: 0, error }] as APImageData[];
    }
  } else if (isImage(fileUrl)) {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    return [{ source: fileUrl, page: 0, url: blobUrl }];
  } else {
    return [];
  }
}

export async function processToImages(
  fileUrls: string[]
): Promise<APImageData[]> {
  const images: APImageData[] = await Promise.all(
    fileUrls.map(async (fileUrl) => {
      try {
        const processedFile = await processFile(fileUrl);
        return processedFile;
      } catch (error) {
        return [{ source: fileUrl, url: "", page: 0, error }] as APImageData[];
      }
    })
  ).then((data) => data.flat());
  return images;
}

export function markAsResolved(
  transactions: APTransaction[],
  resolved: boolean,
  unresolvedIds: string[],
  setPreviewNext: (value: SetStateAction<string>) => void,
  dispatch: AppDispatch,
  userAuth?: string
) {
  if (unresolvedIds.length > 0 && resolved) {
    if (transactions[0].id === unresolvedIds[0]) {
      setPreviewNext(unresolvedIds[1]);
    } else {
      setPreviewNext(unresolvedIds[0]);
    }
  }

  const transactionRefs = transactions.map((t) => ({
    ref: t.ref,
  }));
  dispatch(
    usersActions.UPDATE_USERS_TRANSACTIONS_RESOLVED({
      transactionRefs,
      resolved,
      resolvedBy: getUserName(userAuth ?? ""),
    })
  );
}

export function sumCounts(apMatricsData: ClassificationCount[]) {
  const initialCounts = {
    totalAutoClassifiedTransactions: 0,
    failedClassification: 0,
    classifiedAsInvoice: 0,
    classifiedAsNotInvoice: 0,
    correctlyClassifiedAsInvoice: 0,
    correctlyClassifiedAsNotInvoice: 0,
  };

  return apMatricsData.reduce((acc, curr) => {
    return {
      totalAutoClassifiedTransactions:
        acc.totalAutoClassifiedTransactions +
        (curr.totalAutoClassifiedTransactions ?? 0),
      failedClassification:
        acc.failedClassification + (curr.failedClassification ?? 0),
      classifiedAsInvoice:
        acc.classifiedAsInvoice + (curr.classifiedAsInvoice ?? 0),
      classifiedAsNotInvoice:
        acc.classifiedAsNotInvoice + (curr.classifiedAsNotInvoice ?? 0),
      correctlyClassifiedAsInvoice:
        acc.correctlyClassifiedAsInvoice +
        (curr.correctlyClassifiedAsInvoice ?? 0),
      correctlyClassifiedAsNotInvoice:
        acc.correctlyClassifiedAsNotInvoice +
        (curr.correctlyClassifiedAsNotInvoice ?? 0),
    };
  }, initialCounts);
}

export const subscribeTransactions = (
  dispatch: AppDispatch,
  setPage: (page: number) => void,
  userId: string,
  targetDate?: Date
) => {
  setPage(1);

  dispatch(
    usersActions.SUBSCRIBE_USER_TRANSACTIONS({
      userId,
      targetDate,
    })
  );
  return () => dispatch(usersActions.UNSUBSCRIBE_USER_TRANSACTIONS());
};

export const getUserMultiSelectOption = (userId: string) => {
  const users = store.getState().users.users;
  return users
    .filter((user) => !user.organization && user.id !== userId)
    .map((user) => ({
      userId: user.id,
      name: user.name,
    }));
};
