import { createActions } from "redux/create-actions";
import {
  SET_ASSUMPTION_DATE_FILTER_Payload,
  UPDATE_DEFAULT_USER_PROFILE_Payload,
  UPDATE_LOOKER_CONFIG_Payload,
  UPDATE_LOOKER_EMBEDS_Payload,
  UPDATE_SCRAPERS_CONFIG_Payload,
  UPDATE_SETTINGS_Payload,
} from "./sagas";
import { SettingsState } from "./types";

const actions = createActions("settings", {
  SET_STATE: {} as Partial<SettingsState>,
  GET_SETTINGS: undefined,
  UPDATE_SETTINGS: {} as UPDATE_SETTINGS_Payload,
  SET_ASSUMPTION_DATE_FILTER: {} as SET_ASSUMPTION_DATE_FILTER_Payload,
  GET_SCRAPERS_CONFIG: undefined,
  UPDATE_SCRAPERS_CONFIG: {} as UPDATE_SCRAPERS_CONFIG_Payload,
  GET_ALL_FIELDS: undefined,
  GET_LOOKER_CONFIG: undefined,
  UPDATE_LOOKER_CONFIG: {} as UPDATE_LOOKER_CONFIG_Payload,
  GET_LOOKER_EMBEDS: undefined,
  UPDATE_LOOKER_EMBEDS: {} as UPDATE_LOOKER_EMBEDS_Payload,
  GET_DEFAULT_USER_PROFILE: undefined,
  UPDATE_DEFAULT_USER_PROFILE: {} as UPDATE_DEFAULT_USER_PROFILE_Payload,
});

export default actions;
