import { Icon, Tooltip } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { useMemo, useRef } from "react";
import { EditAttributeDialogRef } from "redux/types";
import EditAttributeDialog from "../edit-attribute-dialog";
import style from "./index.module.scss";

type Props = {
  hoveredValue: {
    key: string;
    val: string;
  };

  datum: any;

  col: any;

  getFieldValue: any;
  module: string;
  attrMap: Map<string, string>;
};

const TableCell = ({
  hoveredValue,
  datum,
  col,
  getFieldValue,
  module,
  attrMap,
}: Props) => {
  const countsEditableFields = [
    "state",
    "deleted",
    "dismissed",
    "startTime",
    "endTime",
  ];

  const dialogRef = useRef<EditAttributeDialogRef>(null);
  const fieldValue = useMemo(
    () => getFieldValue(col.name, datum[col.name]),
    [col.name, datum, getFieldValue]
  );

  return (
    <>
      <div
        className={cn(
          hoveredValue.key === col.name &&
            hoveredValue.val === datum[col.name] &&
            typeof fieldValue === "string" &&
            style.hovered,
          style.row_value
        )}
        style={{
          cursor:
            (module !== "counts" &&
              module !== "invoices-table-view" &&
              module !== "ap-transactions" &&
              module !== "integration-monitor") ||
            (module === "counts" && countsEditableFields.includes(col.name))
              ? "pointer"
              : "not-allowed",
        }}
        onClick={() => {
          if (
            (module !== "counts" &&
              module !== "invoices-table-view" &&
              module !== "ap-transactions" &&
              module !== "integration-monitor") ||
            (module === "counts" && countsEditableFields.includes(col.name))
          ) {
            if (dialogRef.current) {
              dialogRef.current.handleOpenDialog();
            }
          }
        }}
      >
        <span>
          {typeof fieldValue === "string" && fieldValue.length > 50 ? (
            <Tooltip minimal content={`${fieldValue.slice(0, 200) + "..."}`}>
              {fieldValue.slice(0, 50) + "..."}
            </Tooltip>
          ) : (
            fieldValue
          )}
        </span>
        <Icon
          icon="edit"
          className={style.edit_icon}
          style={{
            opacity:
              hoveredValue.key === col.name &&
              hoveredValue.val === datum[col.name] &&
              typeof fieldValue === "string"
                ? "100%"
                : "0%",
          }}
        />
      </div>
      <EditAttributeDialog
        ref={dialogRef}
        attrMap={attrMap}
        attributeTarget={col.name}
        attributeValue={datum[col.name]}
        id={datum.id}
        module={module}
        countRef={datum.ref}
      />
    </>
  );
};

export default TableCell;
