import { Button, Dialog, DialogBody, InputGroup } from "@blueprintjs/core";
import { WithId } from "@stockifi/shared";
import { IAppTutorial } from "components/app-tutorials/types";
import { useState } from "react";
import { IChecklistGroup } from "..";
import { createChecklist } from "services/app-tutorials";
import { useAppDispatch } from "redux/hooks";
import actions from "redux/alert/actions";
import NewGroup from "./new-group";

interface Props {
  tutorials: WithId<IAppTutorial>[];
  fetchChecklists: () => void;
}

const AddNewChecklist = ({ tutorials, fetchChecklists }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [newGroups, setNewGroups] = useState<IChecklistGroup[]>([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [title, setTitle] = useState("");

  const handleOnClose = () => {
    setOpenDialog(false);
    setNewGroups([]);
  };

  const handleAddNewGroup = () => {
    setNewGroups([...newGroups, { title: "", tutorials: [] }]);
  };

  const handleCreateChecklist = async () => {
    setCreateLoading(true);
    await createChecklist(title, newGroups)
      .then(() => {
        handleOnClose();
        fetchChecklists();
        dispatch(actions.SUCCESS("Checklist created successfully"));
      })
      .catch((err) => {
        dispatch(actions.ERROR(`Error creating checklist: ${err}`));
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  return (
    <>
      <Button icon="plus" onClick={() => setOpenDialog(true)}>
        Add new checklist
      </Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={handleOnClose}
        title="New Checklist"
        style={{
          maxHeight: "80vh",
        }}
      >
        <DialogBody>
          <InputGroup
            fill
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Checklist title"
            style={{ marginBottom: 20 }}
          />
          <Button onClick={handleAddNewGroup}>Add new group</Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              margin: "20px 0",
            }}
          >
            {newGroups.map((group, index) => (
              <NewGroup
                key={index}
                index={index}
                group={group}
                newGroups={newGroups}
                setNewGroups={setNewGroups}
                tutorials={tutorials}
              />
            ))}
          </div>
          <Button
            fill
            disabled={newGroups.length === 0}
            loading={createLoading}
            onClick={handleCreateChecklist}
          >
            Create Checklist
          </Button>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default AddNewChecklist;
