export const selections = {
  InvoiceNumber: "InvoiceNumber",
  Supplier: "Supplier",
  DeliveryDate: "DeliveryDate",
  FoodTotal: "FoodTotal",
  Item: "Item",
  LastItemQuantity: "LastItemQuantity",
  LastItemTotal: "LastItemTotal",
  AddOpenItem: "AddOpenItem",
  AddDiverseItem: "AddDiverseItem",
  AutoDetectDiverseItems: "AutoDetectDiverseItems",
  LastItemCost: "LastItemCost",
  FillSearchInsideItem: "FillSearchInsideItem",
};

export const ocrHotkeys = [
  {
    combo: "ctrl + [",
    global: true,
    label: "Rotate Image Left",
  },
  {
    combo: "ctrl + ]",
    global: true,
    label: "Rotate Image Right",
  },
  {
    combo: "1",
    global: true,
    label: "Invoice Number",
  },
  {
    combo: "2",
    global: true,
    label: "Supplier Number",
  },
  {
    combo: "3",
    global: true,
    label: "Delivery Date",
  },
  {
    combo: "4",
    global: true,
    label: "Food Total",
  },
  {
    combo: "5",
    global: true,
    label: selections.Item,
  },
  {
    combo: "7",
    global: true,
    label: "Quantity",
  },
  {
    combo: "u",
    global: true,
    label: "Quantity x24",
  },
  {
    combo: "i",
    global: true,
    label: "Quantity x12",
  },
  {
    combo: "o",
    global: true,
    label: "Quantity x6",
  },
  {
    combo: "8",
    global: true,
    label: "Total",
  },
  {
    combo: "9",
    global: true,
    label: "Add Open Item",
  },
  {
    combo: "0",
    global: true,
    label: "Add Diverse Item",
  },
  {
    combo: "d",
    global: true,
    label: "Auto Detect Diverse Items",
  },
  {
    combo: "z",
    global: true,
    label: "Select Names (When floating buttons are active)",
  },
  {
    combo: "x",
    global: true,
    label: "Select Quantities (When floating buttons are active)",
  },
  {
    combo: "c",
    global: true,
    label: "Select Totals (When floating buttons are active)",
  },
  {
    combo: "a",
    global: true,
    label: "Bulk Add Open Items (When floating buttons are active)",
  },
];
