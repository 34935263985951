import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { IntegrationMonitorState } from "./types";

export const imInitialState: IntegrationMonitorState = {
  loading: false,
  scrapersLogs: {
    data: [],
  },
  loadingScrapersLogs: false,
  isUpdating: false,
  updatingList: [],
  inFocus: null,
  scrapersData: {},
  loadingScrapersData: true,
  loadingImages: false,
  images: {},
  openedImage: [],
  imagesLogId: "",
  loadingScrapersLogsData: true,
  posFiles: [],
  integrationsFields: {},
  scraperDataToSummary: [],
  scraperLogIds: [],
  loadingImMetrics: false,
  imMetrics: [],
  loadingAllSalesData: false,
  calendarData: [],
  loadingCalendarData: true,
};

export default createReducer(imInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.UPDATE_SCRAPERS_DATA_STATE, (state, action) => {
    const scrapersData = { ...state.scrapersData };
    return {
      ...state,
      scrapersData: { ...scrapersData, ...action.payload },
    };
  });
  builder.addCase(actions.UPDATE_FILES_DATA_STATE, (state, action) => {
    const images = { ...state.images };
    return {
      ...state,
      images: { ...images, ...action.payload },
    };
  });
  builder.addCase(actions.ADD_LOADING_STATE, (state, action) => ({
    ...state,
    updatingList: [...state.updatingList, action.payload],
  }));
  builder.addCase(actions.REMOVE_LOADING_STATE, (state, action) => {
    const ul1 = [...state.updatingList];
    return {
      ...state,
      updatingList: ul1.filter((obj) => action.payload !== obj),
    };
  });
  builder.addDefaultCase((state) => state);
});
