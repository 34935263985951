import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setDocsListener(docName: string): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const collRef = collection(db, docName);
    const unsub = onSnapshot(
      collRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: DocumentData[] = [];

        for (const doc of snapshot.docs) {
          data.push({
            ...doc.data(),
            id: doc.id,
            refPath: doc.ref.path,
          });
        }

        emitter(data);
      }
    );

    return () => unsub();
  });
}

export function setDocListener(refPath: string): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const docRef = doc(db, refPath);
    const unsub = onSnapshot(docRef, (doc) => {
      emitter({ ...doc.data() });
    });

    return () => unsub();
  });
}
